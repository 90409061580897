// import { ChevronRightRounded } from '@mui/icons-material';
import { ChevronRightRounded } from '@mui/icons-material';
import { Container, Divider } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { bg_brown, brown } from '../../Configs/colors';
import images from '../../Configs/images';
// import images from '../../Configs/images';
import { amplytics } from '../../utils/helper';
// import 'react-tiktok/dist/index.css';

export default function Component({ classes }) {
  const history = useHistory();
  // console.log(classes, history);
  return (
    <div className={classes.root}>
      <Container>
        <div className="title">Essential Knowledge</div>
      </Container>
      {/* <Box className="card-content free">
        <div className="left-content">
          <div className="title">Become a Dazzling Conversationalist</div>
          <div className="desc">
            How do great conversationalists do it? How do they connect...
          </div>
          <button
            className="more-btn"
            onClick={() => {
              amplytics('Clicks on read more topic: Become a Dazzling Conversationalist');
              history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/members-only-lifechanging-hacks-to-be-a-dazzling-conversationalist-copy' });
            }}
          >
            READ MORE
          </button>
        </div>
        <img src={images.Talk} alt="Become a Dazzling Conversationalist" style={{ width: '40%' }} />
      </Box> */}
      {/* <Box className="explore-card">
        <div style={{
          padding: '8px 8px 16px',
          textAlign: 'center',
        }}
        >
          <img
            src={images.Asset30}
            alt="asset30"
            style={{
              width: '100%',
              height: 120,
              objectFit: 'cover',
              borderRadius: 8,
            }}
          />
          <div style={{ fontSize: 16, fontWeight: '600', marginTop: 4 }}>
            Life-Changing Hacks to be a
            <br />
            Dazzling Conversationalist
          </div>
          <div style={{
            fontSize: 12,
            color: '#606060',
            marginTop: 6,
          }}
          >
            5 min. Article
          </div>
        </div>
        <div style={{
          textAlign: 'center',
          background: '#fff',
        }}
        >
          <Button
            sx={{
              py: 1.4,
              color: '#000',
            }}
            onClick={() => {
              amplytics('Clicks on read more topic: Become a Dazzling Conversationalist');
              history.push('/explore/topic', { topicLink: 'https://explore.kopidate.com/members-only-lifechanging-hacks-to-be-a-dazzling-conversationalist-copy' });
            }}
            endIcon={(<ChevronRightRounded />)}
          >
            READ
          </Button>
        </div>
      </Box> */}
      <Container>
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: Who’s Laughing Now?');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/whos-laughing-now-humour-styles-and-your-dating-life-77d8d527ea31' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              Who&apos;s Laughing Now? Humour
              Styles and Your Dating Life
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              “The librarian was the dog!”, you say emphatically, ears
              bracing for the impact of the uproarious laughter to soon...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>4 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/3oEjHAUOqG3lSS0f1C" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
        <Divider />
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: Who’s Laughing Now?');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/the-brief-history-of-dating-97bf4798fc82' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              The Brief History Of Dating
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              Dating has changed lots over the past few years. But it&apos;s
              hard to imagine that just a few years ago, the dating
              habits that we know today were...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>7 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/26mkhMYkitO7DoJuU" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
      </Container>

      <div style={{ background: bg_brown, paddingTop: 16 }} className="center">
        <div style={{
          fontSize: 18,
          fontWeight: '700',
          color: brown,
        }}
        >
          Homemade Brew with Tia
        </div>
        <img src={images.HomeMade} alt="homemade" width="200" style={{ marginTop: 12 }} />
        <div style={{ fontSize: 12, margin: '12px 32px 0' }}>
          Meet Tia: &apos;The sea makes me feel at peace,
          it calms my mind and lets all my thoughts go.
          I believe that everyone has something to escape to,
          and for me, that is the ocean...
        </div>
        <div
          className="flex align-items-center"
          style={{
            justifyContent: 'center',
            background: '#1D1E1E',
            marginTop: 16,
            color: '#fff',
            fontSize: 16,
            padding: '8px 0',
            cursor: 'pointer',
          }}
          onClick={() => window.open('https://www.instagram.com/p/CfN-pnNPHAL/', '_blank')}
        >
          Read more
          {' '}
          <ChevronRightRounded />
        </div>
      </div>

      <Container>
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: Hook-up Culture and the Disempowerment of Women');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/social-commentary-hook-up-culture-and-the-disempowerment-of-women-2cf6e893052b' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              Hook-up Culture and the Disempowerment of Women
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              According to the philosopher Immanuel Kant,
              human beings should be treated as an ends-in-themselves
              and not as a means to something else...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>6 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/gHbgunYtZZ7w8Izf0O" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
        <Divider />
        <div
          className="flex align-items-center"
          style={{ gap: 4 }}
          onClick={() => {
            amplytics('Clicks on read more topic: The Cycle of Breakups');
            history.push('/explore/topic', { topicLink: 'https://medium.com/kopi-date/the-cycle-of-breakups-faf3e9ab9145' });
          }}
        >
          <div style={{ width: '60%' }}>
            <div
              style={{ fontSize: 14, fontWeight: '700' }}
            >
              The Cycle of Breakups
            </div>
            <div style={{ fontSize: 10, marginTop: 8 }}>
              Relationship breakups are hard. A whole wave of emotions
              come rushing over you and when you’re left to face this
              emotional conflict alone — it could be daunting...
            </div>
            <div className="mute" style={{ fontSize: 10, marginTop: 8 }}>7 min read</div>
          </div>
          <div style={{ width: '40%' }}>
            <iframe src="https://giphy.com/embed/vokkuERklQbGU" style={{ width: '100%' }} frameBorder="0" className="giphy-embed" allowFullScreen title="giphy" />
          </div>
        </div>
      </Container>

    </div>
  );
}
