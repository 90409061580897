/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  Button, ClickAwayListener, Container, Grid, Grow, IconButton, Popper, TextField,
} from '@mui/material';
import { ChevronLeft, CloseRounded, ErrorOutlineRounded } from '@mui/icons-material';
import { httpsCallable } from 'firebase/functions';
import { brown, grey } from '../../Configs/colors';
import images from '../../Configs/images';
import { BaseModal, BottomNav } from '../../Elements';
import { getNextMatch, getTodaysMatch, getUserData } from '../../utils/storage';
import { auth, fFunction } from '../../Configs/firebase';
import { amplytics } from '../../utils/helper';

export default function RescheduleDate({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Reschedule Date';
    amplytics('RescheduleDate View');
  }, []);
  const user = getUserData();
  // const gender = user?.signupInfo?.gender;

  const [openTooltip, setOpenTooltip] = useState(false);
  const anchorEl = useRef(null);

  const nextMatch = getTodaysMatch() || getNextMatch();
  const [loading, setLoading] = useState(false);

  // const iHaveConfirmed = () => {
  //   if ((gender === 'Male' && nextMatch.maleConfirmation) ||
  // (gender === 'Female' && nextMatch.femaleConfirmation)) {
  //     return true;
  //   }
  //   return false;
  // };

  const [confirm, setConfirm] = useState(false);
  const [reason, setReason] = useState('');

  const toggleConfirmation = () => {
    setConfirm(!confirm);
  };

  useEffect(() => {
    if (!nextMatch && history) {
      alert('You dont have upcoming date');
      history.replace('/');
    }
  }, []);
  // console.log(nextMatch);

  const handleClose = () => {
    history.goBack();
  };

  const proceedReschedule = async () => {
    if (nextMatch.askForReschedule) {
      history.push('/chat');
      return;
    }

    if (user.rescheduled) {
      history.push(`/reschedule-fee/${nextMatch.matchId}`, { cancellationReason: reason });
    } else {
      setConfirm(false);
      setLoading(true);
      const callable = httpsCallable(fFunction, 'rescheduleDate');
      await callable({
        matchId: nextMatch.matchId,
        cancelledBy: user?.signupInfo?.gender,
        cancellationReason: reason,
        uid: auth.currentUser.uid,
      })
        .then(() => {
          setTimeout(() => {
            window.location.replace('/chat');
          }, 300);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ background: '#fff', minHeight: '100vh' }}>
        {loading
          ? (
            <div className={classes.loading}>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          )
          : (
            <Container sx={{ position: 'relative' }} className={classes.root}>
              <IconButton sx={{ position: 'absolute', right: 4, top: 10 }} onClick={handleClose}>
                <CloseRounded />
              </IconButton>
              <IconButton sx={{ position: 'absolute', left: 4, top: 10 }} onClick={handleClose}>
                <ChevronLeft />
              </IconButton>
              <div className={classes.root}>
                <div style={{
                  textAlign: 'center', fontSize: 22, color: grey, fontWeight: '500',
                }}
                >
                  Reschedule with your date
                </div>

                <div className="flexRow" style={{ marginTop: 20 }}>
                  <div>
                    <ErrorOutlineRounded style={{ fontSize: 18, color: grey }} />
                  </div>
                  <div style={{ color: grey, fontSize: 12, textAlign: 'justify' }}>
                    <strong>Important</strong>
                    <br />
                    <span>
                      To provide the best experience for all members,
                      kindly proceed only in the case of an emergency,
                      {' '}
                    </span>
                    <span ref={anchorEl} onClick={() => setOpenTooltip(true)}>
                      <strong style={{ textDecoration: 'underline', cursor: 'pointer' }}>at a nominal fee.</strong>
                      <img
                        src={images.Info}
                        alt="info"
                        className="info-icon"
                      />
                    </span>
                    <Popper
                      anchorEl={anchorEl.current}
                      disablePortal
                      className={classes.grow}
                      open={openTooltip}
                      role={undefined}
                      transition
                      placement="bottom-start"
                    >
                      {({ TransitionProps }) => (
                        <Grow
                          {...TransitionProps}
                        >
                          <div className={classes.popProfilCard}>
                            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                              <div style={{
                                color: grey, fontSize: 12, padding: 10, backgroundColor: '#f3f3f3', borderRadius: 8,
                              }}
                              >
                                <div>
                                  <b>Rescheduling Ticket</b>
                                  {' '}
                                  📅
                                </div>
                                <br />
                                <div>
                                  Rescheduling tickets are charged at
                                  <br />
                                  $7, to be billed directly. First-time
                                  <br />
                                  rescheduling fee will be waived.
                                </div>
                              </div>
                            </ClickAwayListener>
                          </div>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>

                <div>
                  <img alt="chat-example" src={images.ChatExample} style={{ width: '100%', marginTop: 20 }} />
                </div>

                <div className="flexRow" style={{ marginTop: 20 }}>
                  <div>⏳</div>
                  <div style={{ color: grey, fontSize: 12, textAlign: 'justify' }}>
                    <strong>How to reschedule</strong>
                    <br />
                    Use the anonymous messaging chat to facilitate
                    the change of timings/ date with your date
                    {' '}
                    <strong>within 2 days.</strong>
                  </div>
                </div>

                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 12,
                  marginTop: 20,
                }}
                >
                  <Button
                    id="btn-reschedule-close"
                    fullWidth
                    style={{
                      color: brown, border: `1px solid ${brown}`, textTransform: 'none', borderRadius: 8,
                    }}
                    size="large"
                    onClick={toggleConfirmation}
                  >
                    PROCEED
                  </Button>
                  <Button
                    id="btn-reschedule-submit"
                    fullWidth
                    style={{
                      color: '#fff', backgroundColor: brown, textTransform: 'none', borderRadius: 8,
                    }}
                    onClick={handleClose}
                    size="large"
                  >
                    CLOSE
                  </Button>
                </div>

              </div>
            </Container>
          )}
        {/* <ConfirmationModal
          open={confirm}
          handleClose={toggleConfirmation}
          handleAgree={proceedReschedule}
          agreeText="Yes, I accept"
          disagreeText="Cancel"
          title="Reschedule"
          desc="Are you sure? Please note you'll be billed $7 for
          a rescheduling ticket. First time reschedulings will be waived."
        /> */}
        <BaseModal
          open={confirm}
          handleClose={toggleConfirmation}
          handleSubmit={proceedReschedule}
          submitText="Yes, I accept"
          closeText="Cancel"
          disabled={!reason}
          title="Reschedule"
          desc="Are you sure? Please note you'll be billed $7 for a rescheduling ticket. First time reschedulings will be waived."
        >
          <TextField sx={{ mt: 2 }} value={reason} onChange={({ target }) => setReason(target.value)} label="Reschedule reason" fullWidth rows={3} multiline variant="outlined" />
        </BaseModal>
        <BottomNav active={0} />
      </Grid>
    </Grid>
  );
}
