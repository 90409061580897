/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import {
  getLastMatch, getTodaysMatch, getUserData, setUserData,
} from '../../utils/storage';
import images from '../../Configs/images';
import { BrownButton, ConfirmationModal, OrangeButton } from '../../Elements';
import { auth, fFunction, firestore } from '../../Configs/firebase';
import { amplytics } from '../../utils/helper';
import { bg_brown, brown, grey } from '../../Configs/colors';
// import { Link } from 'react-router-dom';
import {isMobile} from 'react-device-detect';

export default function Component({ classes, history }) {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  useEffect(async () => {
    document.title = 'Kopi Date Experience - Conclude';
    await amplytics(`Conclude step ${step}`);
  }, [step]);
  const [match, setMatch] = useState({});
  const [currentFeedback, setCurrentFeedback] = useState({
    conservationRatings: 70,
    conservationSkills: '0',
    attireAndHygine: '0',
    openMindednessAndAuthenticity: '0',
    conduct: '0',
    ratingElaboration: '',
    toMyDateMsg: '',
    openToConnect: "Yes - I'm open to connect",
    sharedContact: null,
    genuineFeedback: '',
    preferedDate: 'Similar to my recent date',
    additionalFeedback: '',
    aspectsYouCanImprove: [],
  });

  const [slide, setSlide] = useState(true);
  const [loop, setLoop] = useState();
  const todaysMatch = getTodaysMatch()
  const lastMatch = getLastMatch()

    useEffect(() => {
        if (isMobile) {
            window.location.assign(process.env.REACT_APP_DEEPLINK + `/conclude`)
        }
    }, [])

  useEffect(() => {
    let newVal = currentFeedback.conservationRatings;
    let direction = 'right';
    if (step === 1) {
      setLoop(
        setInterval(() => {
          if (newVal === 100) {
            direction = 'left';
          } else if (newVal === 75) {
            direction = 'right';
          }

          if (direction === 'left') {
            newVal -= 1;
          } else if (direction === 'right') {
            newVal += 1;
          }
          if (slide) {
            setCurrentFeedback({ ...currentFeedback, conservationRatings: newVal });
          }
        }, 25),
      );
    }

    function cleanup() {
      console.log('cleaning up');
      clearInterval(loop);
    }

    if (!slide) {
      cleanup();
    }

    return cleanup;
  }, [slide, step]);

  const surveyVal = ['1', '2', '3', '4', '5'];
  const user = getUserData();
  const { matchId } = useParams();

  const gender = user?.signupInfo?.gender;

  const [feedbackExist, setFE] = useState(false);
  const [partnerName, setPartnerName] = useState('');
  const checkFeedback = (matchData) => {
    if ((gender === 'Male' && matchData.maleFeedback)) {
      setFE(true);
      setPartnerName(matchData.signupInfoFemale?.fullName);
    } else if ((gender === 'Female' && matchData.femaleFeedback)) {
      setFE(true);
      setPartnerName(matchData.signupInfoMale?.fullName);
    }
  };

  const checkValidMatch = async () => {
    const matchRef = doc(firestore, `userPrivateData/${auth.currentUser?.uid}/matches/${matchId}`);
    const matchSnap = await getDoc(matchRef);
    if (matchSnap.exists) {
      const matchData = matchSnap.data();
      // console.log(matchData);
      if (!matchData?.dateCanceled) {
        console.log('no issue, its valid match');
        setMatch(matchData);
        setLoading(false);
        checkFeedback(matchData);
      } else { history.replace('/'); }
    } else {
      history.replace('/');
    }
  };

  useEffect(() => {
    if (!matchId) {
      if (todaysMatch || lastMatch) {
        setMatch(todaysMatch || lastMatch);
        setLoading(false);
        checkFeedback(todaysMatch || lastMatch);
      } else {
        history.replace('/');
      }
    } else checkValidMatch();
    return null;
  }, []);

  const handleChangeFeedback = (e) => {
    setSlide(false);
    setCurrentFeedback({ ...currentFeedback, [e.target.name]: e.target.value });
  };

  const handleSubmitConclude = () => {
    setLoading(true);
    const callable = httpsCallable(fFunction, 'concludeMatchFeedBack');
    callable({ feedback: currentFeedback, matchId: matchId || match.matchId })
      .then(async () => {
        await setUserData(auth.currentUser);
        await amplytics('Conclude date completion', { matchId: matchId || match.matchId });
        history.replace('/date-concluded');
        setLoading(false);
      })
      .catch((err) => {
        console.log('Err:', err);
        alert('Please try again!');
        setLoading(false);
      });
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
    else history.goBack();
  };

  const renderStep = (s) => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      fontSize: 14,
      color: '#606060',
    }}
    >
      {[1, 2, 3, 4].map((e) => (
        <div
          style={{
            margin: '20px 8px 14px',
            padding: '0 2px 2px',
            fontWeight: e === s ? 'bold' : '500',
            borderBottom: e === s ? '2px solid #606060' : 'none',
          }}
          key={e}
        >
          {e}
        </div>
      ))}
    </div>
  );

  // const renderText = () => {
  //   if (currentFeedback.conservationRatings > 90) {
  //     return '🤩 Very much! It was fantastic';
  //   } if (currentFeedback.conservationRatings > 80) {
  //     return '😊 A lot! It was great';
  //   } if (currentFeedback.conservationRatings > 70) {
  //     return '😌 Quite a bit! It was good';
  //   } if (currentFeedback.conservationRatings > 60) {
  //     return '🙂 Not that bad! It was ok';
  //   }
  //   return '😐 So-so. It was average';
  // };

  const renderText2 = () => ['Please provide a short elaboration for the above ratings (You rated: ', String(currentFeedback.conservationRatings), ' for the date)'].join('');

  const aspectsYouCanImproveOpt = ['Empathy', 'Confidence', 'Presentability', 'Conversational skills', 'Authenticity', 'Open-mindedness'];

  const stepOneValid = currentFeedback.ratingElaboration?.length >= 30
  && currentFeedback.conservationSkills !== '0'
  && currentFeedback.attireAndHygine !== '0'
  && currentFeedback.openMindednessAndAuthenticity !== '0'
  && currentFeedback.conduct !== '0';

  const toMyDateMsgValid = currentFeedback.toMyDateMsg?.length >= 30;
  const openToConnectValid = (currentFeedback.openToConnect !== "Yes - I'm open to connect" || currentFeedback.sharedContact?.length >= 5);
  const stepTwoValid = toMyDateMsgValid && openToConnectValid;
  // const stepThreeValid = currentFeedback.genuineFeedback?.length >= 50;
  // const stepFourValid = currentFeedback.additionalFeedback?.length >= 50;

  return (
    <>
      <ConfirmationModal
        open={feedbackExist}
        title="Are you sure?"
        desc={`You have already completed the review for the Kopi Date with ${partnerName} No further action is required. You may still continue if you want to revise your response.`}
        handleAgree={() => setFE(false)}
        agreeText="Continue"
        noCancel
      />
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Box className={classes.loading} sx={{ display: loading ? 'flex' : 'none' }}>
            <img alt="loading" src={images.Loading} width="100" height="100" />
          </Box>
          <Box className={classes.root} sx={{ display: loading ? 'none' : 'block' }}>
            <div className="progress">
              <LinearProgress className="progress-bar" value={step * 25} variant="determinate" />
            </div>
            <IconButton className="back" onClick={handleBack}><ChevronLeftRounded /></IconButton>
            <div className="title center">
              How was your date with
              {' '}
              {gender === 'Male' ? match?.femaleAlias : match?.maleAlias}
            </div>

            {step === 0
              && (
              <Container>
                <section>
                  <div className="desc center">Conclude the date</div>
                  <div className="benefits">
                    <div style={{ color: '#606060', fontSize: 14 }}>Tell us how your date went:</div>
                    <div className="benefit">
                      <div>Active learning for personalised dates</div>
                      <img src={images.Tickcircle} alt="tick" height="28" width="28" />
                    </div>
                    <div className="benefit">
                      <div>Improve your upcoming date experience</div>
                      <img src={images.Tickcircle} alt="tick" height="28" width="28" />
                    </div>
                  </div>
                  <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src={images.Conclude2} alt="please conclude" style={{ width: '90%' }} />
                  </div>
                  <div style={{
                    display: 'flex',
                    margin: '20px 10px 0',
                    gap: 8,
                    alignItems: 'flex-start',
                  }}
                  >
                    <img alt="kopi date" src={images.Idea} style={{ width: 22, height: 22, marginTop: 2 }} />
                    <div className="info">Your response and feedback will be kept strictly confidential (except the message you leave for your date, of course!), and will only be referenced for future curations.</div>
                  </div>
                  <OrangeButton onClick={handleNext} text="LET'S BEGIN" fullwidth size="large" styles={{ fontWeight: 'bold', marginTop: 50 }} />
                </section>
              </Container>
              )}

            {step === 1
              && (
              <Container>
                <section>
                  {renderStep(1)}
                  <div className="head1 center">RATE THE FOLLOWING STATEMENTS</div>
                  <div style={{
                    background: '#fff', padding: '16px 0', borderRadius: 16, marginTop: 20,
                  }}
                  >
                    <div className="head2 center" style={{ marginBottom: 28 }}>I enjoyed the conversation with my date</div>
                    <input
                      name="conservationRatings"
                      onChange={handleChangeFeedback}
                      value={currentFeedback.conservationRatings}
                      type="range"
                      min={0}
                      max={100}
                      className="slider"
                      id="myRange"
                    />
                    <div style={{
                      marginTop: -20,
                      fontSize: 14,
                      color: '#606060',
                      padding: '0 14% 0 10%',
                    }}
                    >
                      <div style={{ marginLeft: `${currentFeedback.conservationRatings}%` }}>{currentFeedback.conservationRatings}</div>
                    </div>
                    <div
                      className="flex space-between"
                      style={{ margin: '4px 8% 8px', fontSize: 12, color: '#606060' }}
                    >
                      <div>0</div>
                      {/* <div>50</div> */}
                      <div>100</div>
                    </div>
                    <div className="right" style={{ fontSize: 12, marginRight: 16 }}>
                      <div>👑 A great date</div>
                    </div>
                  </div>

                  <Container>
                    <div className="box consider">
                      <div className="head2 center">I consider my Kopi Date&apos;s</div>
                      <Container>
                        <div className="form-label" style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                          <div>Poor</div>
                          <div>Excellent</div>
                        </div>
                        <div className="head2 mt-2">Conversation skills</div>
                        <FormControl sx={{ display: 'flex', mt: 1 }}>
                          <RadioGroup name="conservationSkills" onChange={handleChangeFeedback} value={currentFeedback.conservationSkills} row sx={{ justifyContent: 'space-between' }}>
                            {surveyVal.map((val) => (
                              <FormControlLabel sx={{ margin: 0 }} value={val} key={val} control={<BpRadio />} label={val} labelPlacement="top" />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        <div className="head2 mt-2">Attire and hygiene</div>
                        <FormControl sx={{ display: 'flex', mt: 1 }}>
                          <RadioGroup name="attireAndHygine" onChange={handleChangeFeedback} value={currentFeedback.attireAndHygine} row sx={{ justifyContent: 'space-between' }}>
                            {surveyVal.map((val) => (
                              <FormControlLabel sx={{ margin: 0 }} value={val} key={val} control={<BpRadio />} label={val} labelPlacement="top" />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        <div className="head2 mt-2">Open-mindedness and authenticity</div>
                        <FormControl sx={{ display: 'flex', mt: 1 }}>
                          <RadioGroup name="openMindednessAndAuthenticity" onChange={handleChangeFeedback} value={currentFeedback.openMindednessAndAuthenticity} row sx={{ justifyContent: 'space-between' }}>
                            {surveyVal.map((val) => (
                              <FormControlLabel sx={{ margin: 0 }} value={val} key={val} control={<BpRadio />} label={val} labelPlacement="top" />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        <div className="head2 mt-2">Conduct (mannerisms, demeanour)</div>
                        <FormControl sx={{ display: 'flex', mt: 1 }}>
                          <RadioGroup name="conduct" onChange={handleChangeFeedback} value={currentFeedback.conduct} row sx={{ justifyContent: 'space-between' }}>
                            {surveyVal.map((val) => (
                              <FormControlLabel sx={{ margin: 0 }} value={val} key={val} control={<BpRadio />} label={val} labelPlacement="top" />
                            ))}
                          </RadioGroup>
                        </FormControl>
                        <div className="mute" style={{ color: 'salmon', marginTop: 8 }} hidden={stepOneValid}>Variations in your ratings will improve the curation process</div>
                      </Container>
                    </div>

                    <div>
                      <Container>
                        <div className="head2 mt-2">{renderText2()}</div>
                        <TextField
                          name="ratingElaboration"
                          error={!stepOneValid}
                          onChange={handleChangeFeedback}
                          value={currentFeedback.ratingElaboration}
                          sx={{ background: '#fff', mt: 2 }}
                          fullWidth
                          multiline
                          rows={2}
                          placeholder="Type here"
                        />
                        <div className="mute" hidden={currentFeedback.ratingElaboration?.length >= 30}>Minimum 30 characters</div>
                      </Container>
                    </div>
                  </Container>
                  <OrangeButton
                    onClick={stepOneValid ? handleNext : null}
                    text="CONTINUE"
                    fullwidth
                    size="large"
                    styles={{ fontWeight: 'bold', marginTop: 40 }}
                  />
                </section>
              </Container>
              )}

            {step === 2
            && (
            <div>
              {renderStep(2)}
              <div className="head1 center">CONVERSE & CONNECT</div>
              <div style={{ textAlign: 'center', margin: '40px 0' }}>
                <img alt="kopi date" src={images.ConverseConnect} style={{ width: '90%' }} />
              </div>
              <div style={{
                background: '#ffa06b ',
                padding: '20px 0',
                color: '#fff',
                marginTop: 20,
              }}
              >
                <Container>
                  <Container>
                    <div
                      className="head2"
                    >
                      Write a message to my Kopi Date 🖌 (your Kopi Date will see this)
                    </div>
                    <div style={{ fontSize: 12, marginTop: 4 }}>
                      Be kind and share your appreciation for your date! An inside joke,
                      a topic you enjoyed during the conversation, what you appreciate
                      about him/her!
                    </div>
                    <div style={{ borderRadius: 10, overflow: 'hidden', marginTop: 8 }}>
                      <TextField error={!toMyDateMsgValid} name="toMyDateMsg" onChange={handleChangeFeedback} value={currentFeedback.toMyDateMsg} sx={{ background: '#fef8f4' }} fullWidth multiline rows={5} placeholder="To my Kopi Date... (Please note that your date will see this message being addressed to them)" />
                    </div>
                    <div className="mute" hidden={toMyDateMsgValid}>Minimum 40 characters - show your appreciation here</div>
                  </Container>
                </Container>
              </div>
              <Container>
                <Container>
                  <section style={{ marginTop: 20 }}>
                    <div className="head2">Connect with my Kopi Date</div>
                    <div className="head-desc">If both parties have indicated &apos;Yes&apos;, we&apos;ll share your preferred contact (IG, Telegram, Phone) to make a friend!</div>
                    <div className="box connect">
                      <FormControl sx={{ px: 1 }}>
                        <RadioGroup name="openToConnect" onChange={handleChangeFeedback} value={currentFeedback.openToConnect}>
                          {["Yes - I'm open to connect", "No - I'm not open to make a friend", "It's alright - we've connected offline!"].map((val) => (
                            <FormControlLabel sx={{ margin: 0 }} value={val} key={val} control={<BpRadio />} label={val} labelPlacement="right" />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </section>
                  <section style={{ marginTop: 20 }} hidden={currentFeedback.openToConnect !== "Yes - I'm open to connect"}>
                    <div className="head2">Please share the following contact with my date!</div>
                    <div className="head-desc">It can be your IG handle, Telegram, number or even your email address. Kindly indicate which platform in brackets (IG)</div>
                    <TextField error={!openToConnectValid} name="sharedContact" onChange={handleChangeFeedback} value={currentFeedback.sharedContact} sx={{ background: '#fff', mt: 2 }} fullWidth multiline rows={2} placeholder="Type here" />
                  </section>
                  <div className="mute" hidden={openToConnectValid}>Please share your contact info</div>
                </Container>
                <OrangeButton onClick={stepTwoValid ? handleNext : null} text="CONTINUE" fullwidth size="large" styles={{ fontWeight: 'bold', marginTop: 40 }} />
              </Container>
            </div>
            )}

            {step === 3
              && (
              <Container>
                <Container>
                  {renderStep(3)}
                  <div className="head1 center">COMMUNITY OF GROWTH</div>
                  <div style={{ textAlign: 'center', margin: '40px 0' }}>
                    <img alt="kopi date" src={images.CommunityGrowth} style={{ width: '90%' }} />
                  </div>
                  <div className="head-desc center">Kopi Date is a space for vulnerability, encouragement and personal growth!</div>
                  <section style={{ marginTop: 20 }}>
                    <div className="head2">Share your genuine feedback (about your date) to us!</div>
                    <div className="head-desc">Kopi Date emphasises on safety and comfort of experiences at the same time, we will keep this anonymous and only between ourselves.</div>
                    <TextField name="genuineFeedback" onChange={handleChangeFeedback} value={currentFeedback.genuineFeedback} sx={{ background: '#fff', mt: 2 }} fullWidth multiline rows={2} placeholder="Type here" />
                    {/* <div
                    className="mute"
                    hidden={stepThreeValid}>Minimum 30 characters</div> */}
                  </section>

                  <Divider sx={{ my: 2 }} />
                  <div className="head1 center">SELF-AWARENESS</div>
                  <section style={{ marginTop: 16 }}>
                    <div className="head2">What do you think your date would have thought of you?</div>
                    <TextField name="dateThoughtOfYou" onChange={handleChangeFeedback} value={currentFeedback.dateThoughtOfYou} sx={{ background: '#fff', mt: 2 }} fullWidth multiline rows={2} placeholder="Type here" />
                  </section>
                  <section style={{ marginTop: 16 }}>
                    <div className="head2">Were there aspects you could have improved on?</div>
                    <div className="head-desc">Type or select from the list below</div>
                    <div style={{ marginTop: 16 }}>
                      <Autocomplete
                        multiple
                        options={aspectsYouCanImproveOpt}
                        value={currentFeedback.aspectsYouCanImprove}
                        freeSolo
                        onChange={(e, newVal) => setCurrentFeedback({ ...currentFeedback, aspectsYouCanImprove: newVal })}
                        renderTags={(value, getTagProps) => value.map((option, index) => (
                          <Chip variant="filled" sx={{ bgcolor: '#fff' }} label={option} {...getTagProps({ index })} />
                        ))}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{ bgcolor: bg_brown }}
                            placeholder="Type here & enter"
                          />
                        )}
                      />
                    </div>
                    <div style={{
                      marginTop: 8, color: grey, fontWeight: '700', fontSize: 14,
                    }}
                    >
                      Select adjectives
                    </div>
                    <div style={{
                      display: 'flex',
                      gap: 8,
                      flexWrap: 'wrap',
                      marginTop: 12,
                    }}
                    >
                      {aspectsYouCanImproveOpt.map((opt) => (
                        <Chip
                          key={opt}
                          label={opt}
                          sx={{ color: brown, fontWeight: '500' }}
                          onClick={() => {
                            if (!currentFeedback.aspectsYouCanImprove.includes(opt)) {
                              setCurrentFeedback({ ...currentFeedback, aspectsYouCanImprove: [...currentFeedback.aspectsYouCanImprove, opt] });
                            }
                          }}
                        />
                      ))}
                    </div>
                  </section>
                </Container>
                <OrangeButton
                  onClick={handleNext}
                  text="CONTINUE"
                  fullwidth
                  size="large"
                  styles={{ fontWeight: 'bold', marginTop: 40 }}
                />
              </Container>
              )}

            {step === 4
            && (
            <Container>
              <Container>
                {renderStep(4)}
                <div className="head1 center">MOVING FORWARD</div>
                <section style={{ marginTop: 20 }}>
                  <div className="head2">For your next curation, I&apos;d prefer my dates to be...</div>
                  <div className="box connect">
                    <FormControl sx={{ px: 1 }}>
                      <RadioGroup name="preferedDate" onChange={handleChangeFeedback} value={currentFeedback.preferedDate}>
                        {['Similar to my recent date', 'Less alike my past date!', 'No preference'].map((val) => (
                          <FormControlLabel sx={{ margin: 0 }} value={val} key={val} control={<BpRadio />} label={val} labelPlacement="right" />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </section>
                <section style={{ marginTop: 20 }}>
                  <div className="head2">Additional feedback (For Kopi Date Team)</div>
                  <div className="head-desc">(e.g. on the café, room for improvements, things we&apos;ve done well in, or other suggestions)</div>
                  <TextField name="additionalFeedback" onChange={handleChangeFeedback} value={currentFeedback.additionalFeedback} sx={{ background: '#fff', mt: 2 }} fullWidth multiline rows={2} placeholder="Type here" />
                  {/* <div
                  className="mute"
                  hidden={stepFourValid}>Minimum 30 characters</div> */}
                </section>
              </Container>
              <BrownButton text="SUBMIT" onClick={handleSubmitConclude} fullwidth size="large" styles={{ fontWeight: '500', marginTop: 40 }} />
            </Container>
            )}

          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 32,
  background: 'orange',
  height: 32,
  backgroundColor: '#red',
  '.Mui-focusVisible &': {
    outline: '2px auto orange',
    outlineOffset: 2,
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'orange',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 32,
    height: 32,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

// const top100Films = [
//   { title: 'The Shawshank Redemption', year: 1994 },
//   { title: 'The Godfather', year: 1972 },
//   { title: 'The Godfather: Part II', year: 1974 },
//   { title: 'The Dark Knight', year: 2008 },
//   { title: '12 Angry Men', year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: 'Pulp Fiction', year: 1994 },
//   {
//     title: 'The Lord of the Rings: The Return of the King',
//     year: 2003,
//   },
//   { title: 'The Good, the Bad and the Ugly', year: 1966 },
//   { title: 'Fight Club', year: 1999 },
//   {
//     title: 'The Lord of the Rings: The Fellowship of the Ring',
//     year: 2001,
//   },
//   {
//     title: 'Star Wars: Episode V - The Empire Strikes Back',
//     year: 1980,
//   },
//   { title: 'Forrest Gump', year: 1994 },
//   { title: 'Inception', year: 2010 },
//   {
//     title: 'The Lord of the Rings: The Two Towers',
//     year: 2002,
//   },
//   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { title: 'Goodfellas', year: 1990 },
//   { title: 'The Matrix', year: 1999 },
//   { title: 'Seven Samurai', year: 1954 },
//   {
//     title: 'Star Wars: Episode IV - A New Hope',
//     year: 1977,
//   },
//   { title: 'City of God', year: 2002 },
//   { title: 'Se7en', year: 1995 },
//   { title: 'The Silence of the Lambs', year: 1991 },
//   { title: "It's a Wonderful Life", year: 1946 },
//   { title: 'Life Is Beautiful', year: 1997 },
//   { title: 'The Usual Suspects', year: 1995 },
//   { title: 'Léon: The Professional', year: 1994 },
//   { title: 'Spirited Away', year: 2001 },
//   { title: 'Saving Private Ryan', year: 1998 },
//   { title: 'Once Upon a Time in the West', year: 1968 },
//   { title: 'American History X', year: 1998 },
//   { title: 'Interstellar', year: 2014 },
//   { title: 'Casablanca', year: 1942 },
//   { title: 'City Lights', year: 1931 },
//   { title: 'Psycho', year: 1960 },
//   { title: 'The Green Mile', year: 1999 },
//   { title: 'The Intouchables', year: 2011 },
//   { title: 'Modern Times', year: 1936 },
//   { title: 'Raiders of the Lost Ark', year: 1981 },
//   { title: 'Rear Window', year: 1954 },
//   { title: 'The Pianist', year: 2002 },
//   { title: 'The Departed', year: 2006 },
//   { title: 'Terminator 2: Judgment Day', year: 1991 },
//   { title: 'Back to the Future', year: 1985 },
//   { title: 'Whiplash', year: 2014 },
//   { title: 'Gladiator', year: 2000 },
//   { title: 'Memento', year: 2000 },
//   { title: 'The Prestige', year: 2006 },
//   { title: 'The Lion King', year: 1994 },
//   { title: 'Apocalypse Now', year: 1979 },
//   { title: 'Alien', year: 1979 },
//   { title: 'Sunset Boulevard', year: 1950 },
//   {
//     title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
//     year: 1964,
//   },
//   { title: 'The Great Dictator', year: 1940 },
//   { title: 'Cinema Paradiso', year: 1988 },
//   { title: 'The Lives of Others', year: 2006 },
//   { title: 'Grave of the Fireflies', year: 1988 },
//   { title: 'Paths of Glory', year: 1957 },
//   { title: 'Django Unchained', year: 2012 },
//   { title: 'The Shining', year: 1980 },
//   { title: 'WALL·E', year: 2008 },
//   { title: 'American Beauty', year: 1999 },
//   { title: 'The Dark Knight Rises', year: 2012 },
//   { title: 'Princess Mononoke', year: 1997 },
//   { title: 'Aliens', year: 1986 },
//   { title: 'Oldboy', year: 2003 },
//   { title: 'Once Upon a Time in America', year: 1984 },
//   { title: 'Witness for the Prosecution', year: 1957 },
//   { title: 'Das Boot', year: 1981 },
//   { title: 'Citizen Kane', year: 1941 },
//   { title: 'North by Northwest', year: 1959 },
//   { title: 'Vertigo', year: 1958 },
//   {
//     title: 'Star Wars: Episode VI - Return of the Jedi',
//     year: 1983,
//   },
//   { title: 'Reservoir Dogs', year: 1992 },
//   { title: 'Braveheart', year: 1995 },
//   { title: 'M', year: 1931 },
//   { title: 'Requiem for a Dream', year: 2000 },
//   { title: 'Amélie', year: 2001 },
//   { title: 'A Clockwork Orange', year: 1971 },
//   { title: 'Like Stars on Earth', year: 2007 },
//   { title: 'Taxi Driver', year: 1976 },
//   { title: 'Lawrence of Arabia', year: 1962 },
//   { title: 'Double Indemnity', year: 1944 },
//   {
//     title: 'Eternal Sunshine of the Spotless Mind',
//     year: 2004,
//   },
//   { title: 'Amadeus', year: 1984 },
//   { title: 'To Kill a Mockingbird', year: 1962 },
//   { title: 'Toy Story 3', year: 2010 },
//   { title: 'Logan', year: 2017 },
//   { title: 'Full Metal Jacket', year: 1987 },
//   { title: 'Dangal', year: 2016 },
//   { title: 'The Sting', year: 1973 },
//   { title: '2001: A Space Odyssey', year: 1968 },
//   { title: "Singin' in the Rain", year: 1952 },
//   { title: 'Toy Story', year: 1995 },
//   { title: 'Bicycle Thieves', year: 1948 },
//   { title: 'The Kid', year: 1921 },
//   { title: 'Inglourious Basterds', year: 2009 },
//   { title: 'Snatch', year: 2000 },
//   { title: '3 Idiots', year: 2009 },
//   { title: 'Monty Python and the Holy Grail', year: 1975 },
// ];
