/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import images from '../../Configs/images';
import { BottomNav } from '../../Elements';
import { fFunction } from '../../Configs/firebase';
import { getPricingSubscription, getUserData } from '../../utils/storage';
import { amplytics } from '../../utils/helper';

export default function Component({ classes }) {
  const user = getUserData();
  const [loading, setLoading] = useState(false);
  const [dataPricing, setdataPricing] = useState();

  const isFemale = user?.signupInfo?.gender === 'Female';

  const priceAdventurer = dataPricing ? isFemale
    ? dataPricing[0]?.f_adventurer / 12
    : dataPricing[0]?.m_adventurer / 12 : null;
  const priceEscapade = dataPricing ? isFemale
    ? dataPricing[0]?.f_escapade / 6
    : dataPricing[0]?.m_escapade / 6 : null;
  const priceExplorer = dataPricing ? isFemale
    ? dataPricing[0]?.f_explorer / 3
    : dataPricing[0]?.m_explorer / 3 : null;

  const apiIAdventurer = dataPricing ? isFemale ? dataPricing[1]?.f_adventurer : dataPricing[1]?.m_adventurer : null;
  const apiIAdEscapade = dataPricing ? isFemale ? dataPricing[1]?.f_escapade : dataPricing[1]?.m_escapade : null;
  const apiIAdExplorer = dataPricing ? isFemale ? dataPricing[1]?.f_explorer : dataPricing[1]?.m_explorer : null;

  const chooseSubscription = ({ val, apiID }) => async () => {
    setLoading(true);
    amplytics(`Choose Subscription Plan ${val}`);
    const callable = httpsCallable(fFunction, 'checkoutSubscription');
    await callable({
      plan: val,
      costumerId: user.stripeCustomerId,
      gender: user.signupInfo.gender,
      success_url: window.origin,
      cancel_url: `${window.origin}/subscription`,
      apiID,
    })
      .then((intents) => {
        const { data } = intents;
        window.location.replace(data.session.url);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.title = 'Kopi Date Experience - Subscription';
    amplytics('Subscription View');
    getPricingSubscription()
      .then((s) => setdataPricing(s));
  }, []);
  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        {loading
          ? (
            <div className={classes.loading}>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          ) : (
            <div className={classes.root}>
              <Container>

                {/* 12 Months */}
                <div className="card-price">
                  <div className="center">
                    <div className="head star">
                      <img src={images.Star} alt="star" width="20" height="20" />
                      Popular choice!
                    </div>
                    <img
                      src={images.Pricing2}
                      style={{ width: '60%', margin: '20px 0' }}
                      alt="adhoc"
                    />
                    <div className="head2">Adventurer</div>
                    <div style={{ fontSize: 14, marginTop: 4 }}>(25% off)</div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <Container>
                      <div className="benefit">
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          Access to
                          {' '}
                          <strong>12 Kopi Date Experiences</strong>
                        </div>
                      </div>
                      <div className="benefit">
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          <strong>Top priority</strong>
                          {' '}
                          monthly curations (better and faster curations)
                        </div>
                      </div>
                      <div className="benefit">
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          Exclusive
                          {' '}
                          <strong>surprise</strong>
                          {' '}
                          dates (chance of unique date concepts)
                        </div>
                      </div>
                      <div className="benefit">
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          <strong>A white glove service</strong>
                          {' '}
                          from the entire Kopi Date team
                        </div>
                      </div>
                    </Container>
                  </div>
                  <div style={{ marginTop: 20 }} className="center">
                    {/* {(user.signupInfo?.gender === 'Male') && <div className="price">$40</div>}
                    {(user.signupInfo?.gender === 'Female') && <div className="price">$32</div>} */}
                    <div className="price">
                      AU$
                      {priceAdventurer}
                    </div>
                    <div className="desc">per experience</div>
                    <button className="btn orange" onClick={chooseSubscription({ val: 12, apiID: apiIAdventurer })}>
                      Select
                    </button>
                  </div>
                </div>

                {/* 6 Months */}
                <div className="card-price">
                  <div className="center">
                    <img src={images.Pricing3} style={{ width: '50%', margin: '20px 0' }} alt="adhoc" />
                    <div className="head2">Escapade</div>
                    <div style={{ fontSize: 14, marginTop: 4 }}>(20% off)</div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <Container>
                      <div className="benefit">
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          Access to
                          {' '}
                          <strong>6 Kopi Date Experiences</strong>
                        </div>
                      </div>
                      <div className="benefit">
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          Exclusive
                          {' '}
                          <strong>surprise</strong>
                          {' '}
                          dates (chance of unique date concepts)
                        </div>
                      </div>
                    </Container>
                  </div>
                  <div style={{ marginTop: 20 }} className="center">
                    {/* {(user.signupInfo?.gender === 'Male') && <div className="price">AU$45</div>}
                    {(user.signupInfo?.gender === 'Female') && <div className="price">AU$36</div>} */}
                    <div className="price">
                      AU$
                      {priceEscapade}
                    </div>
                    <div className="desc">per experience</div>
                    <button className="btn orange" onClick={chooseSubscription({ val: 6, apiID: apiIAdEscapade })}>
                      Select
                    </button>
                  </div>
                </div>

                {/* 3 Months */}
                <div className="card-price">
                  <div className="center">
                    <img src={images.Pricing1} style={{ width: '45%', margin: '20px 0' }} alt="adhoc" />
                    <div className="head2">Explorer</div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <Container>
                      <div className="benefit" style={{ justifyContent: 'center' }}>
                        <img src={images.Tick} alt="tick" height="16" width="16" />
                        <div className="desc">
                          Access to
                          {' '}
                          <strong>3 Kopi Date Experiences</strong>
                        </div>
                      </div>
                    </Container>
                  </div>
                  <div style={{ marginTop: 20 }} className="center">
                    {/* {(user.signupInfo?.gender === 'Male') && <div className="price">AU$55</div>}
                    {(user.signupInfo?.gender === 'Female') && <div className="price">AU$45</div>} */}
                    <div className="price">
                      AU$
                      {priceExplorer}
                    </div>
                    <div className="desc">per experience</div>
                    <button className="btn" onClick={chooseSubscription({ val: 3, apiID: apiIAdExplorer })}>
                      Select
                    </button>
                  </div>
                </div>

              </Container>
            </div>
          )}
        <BottomNav active={0} />
      </Grid>
    </Grid>
  );
}
