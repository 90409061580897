/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-danger */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */

import React, {
  Fragment,
  useEffect, useRef, useState,
} from 'react';
import {
  Container,
  Grid, Popper, Grow, ClickAwayListener, SwipeableDrawer, Box, Divider,
} from '@mui/material';
import {
  doc, updateDoc,
} from 'firebase/firestore';
import {
  EmailIcon,
  TelegramIcon,
  WhatsappIcon,
} from 'react-share';
import { TikTok } from 'react-tiktok';
import {
  auth, firestore,
} from '../../Configs/firebase';
import {
  getEnv,
  getGettingToKnowYou,
  getLastMatch,
  getNextMatch,
  getPastDates,
  getProfilePicture,
  getReferralInfo,
  getTodaysMatch,
  getUserData,
  getUserData1,
  setUserData,
} from '../../utils/storage';
import images from '../../Configs/images';
import {
  BottomNav, ConfirmationModal,
} from '../../Elements';
import MonthlyQuestions from '../../Fragments/MonthlyQuestions';
import Recommendation from '../../Fragments/Recommendation';
import ReferralBlock from '../../Fragments/ReferralBlock';
import KitBlock from '../../Fragments/KitBlock';
import QuizesAndGames from '../../Fragments/QuizesAndGames';
import Articles from '../../Fragments/Articles';
import QuoteOfTheDate from '../../Fragments/QuoteOfTheDate';
import ExploreTopic from '../../Fragments/Explore';
import UnderstandYourself from '../../Fragments/UnderstandYourself';
import DateDayModal from '../../Fragments/DateDayModal';
import { amplytics } from '../../utils/helper';
import BannerMobile from '../../Fragments/BannerMobile';

export default function Home({ classes, history }) {
  const user = getUserData();
  const user1 = getUserData1();
  const gettingToKnowYouInfo = getGettingToKnowYou();
  const profilePicture = getProfilePicture();
  const lastMatch = getLastMatch();
  const todaysMatch = getTodaysMatch();
  const nextMatch = getNextMatch();
  const referralInfo = getReferralInfo();
  const env = getEnv();

  const gender = user?.signupInfo?.gender;

  useEffect(() => {
    amplytics('Home View');
  }, []);

  const message = `Have%20you%20tried%20Kopi%20Date?%20Sign%20up%20with%20my%20promo%20code:%20${referralInfo?.promocode}%20and%20you%20will%20get%20off%20the%20waitlist,%20PLUS%20a%20VIP%20pass!%20%F0%9F%A4%9F%F0%9F%8F%BB%20Come%20check%20it%20out!%20https://kopidate.com.au`;

  const [openToShare, setOpenToShare] = useState(false);
  const toggleOpenToShare = () => {
    if (!openToShare) {
      amplytics('Clicks on referral');
    }
    setOpenToShare(!openToShare);
  };

  // const handleOpenDetailPeople = (people) => {
  //   setDetailPeople(people);
  // };

  const [loading, setLoading] = useState(true);
  const [confirmUnlock, setConfirmUnlock] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const anchorEl = useRef(null);

  const handleToggleOpenConfirm = () => {
    setConfirmUnlock(!confirmUnlock);
  };

  useEffect(() => {
    setLoading(false);
    document.title = 'Kopi Date Experience - Home';
    return null;
  }, []);

  const openQuoteOfTheDate = async () => {
    setLoading(true);
    handleToggleOpenConfirm();

    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser?.uid}`);

    await updateDoc(docRef, {
      unlockQuote: true,
    }).then(async () => {
      await setUserData(auth.currentUser);
    }).catch((err) => {
      alert(err.message);
      console.log(err);
    });

    setLoading(false);
  };

  const isUserNeverFillFeedback = () => {
    if (gender === 'Male' && ((todaysMatch && !todaysMatch?.maleFeedback) || (lastMatch && !lastMatch?.maleFeedback))) {
      return true;
    } if (gender === 'Female' && ((todaysMatch && !todaysMatch?.femaleFeedback) || (lastMatch && !lastMatch?.femaleFeedback))) {
      return true;
    }
    return false;
  };

  const handleRedirect = (link) => () => {
    // const linkArr = link.split('/');
    // const destiny = linkArr[linkArr.length - 1];
    // amplytics(`Home redirect to ${destiny}`);
    window.open(link, '_blank');
  };

  const handleTo = (link) => () => {
    // amplytics(`Home tap to ${destiny || link}`);
    history.push(link);
  };

  const pastDates = getPastDates() || [];
  const hasConcludedDate = () => {
    let hasConcluded = false;
    pastDates.forEach((date) => {
      const has = gender === 'Male' ? date.maleFeedback : date.femaleFeedback;
      if (has) {
        hasConcluded = true;
      }
    });
    return hasConcluded;
  };

  const needToDoSomething = () => (
    user?.userState === 1
    || (user?.userState === 2 && hasConcludedDate())
    || (user?.userState === 2 && !user?.onboarded && !lastMatch)
    || (user?.userState === 2 && !user?.planType && user?.price === 1 && !user?.creditBalance)
    || (!gettingToKnowYouInfo && user.userState === 3)
    || todaysMatch
    || user?.userState === 4
    || (user?.userState === 5 && (nextMatch || todaysMatch))
    || (user?.userState === 6 && (nextMatch || todaysMatch))
    || (user?.planType && !user?.hasReadToolkit)
    || (user?.userState === 7 && isUserNeverFillFeedback())
    || isUserNeverFillFeedback()
  );

  const openGuide = async () => {
    window.open('https://docs.google.com/presentation/d/e/2PACX-1vRKDVJdfBJEiwQjuJfvzcVvLr5UkG08BZycB6zIe9A864uKcEnTOKfWe8L-n0TqTGaRtZbQ1ix8EElB/pub?start=false&loop=false&delayms=3000&slide=id.p', '_blank');
    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser?.uid}`);

    await updateDoc(docRef, {
      hasReadToolkit: true,
    }).then(async () => {
      await setUserData(auth.currentUser);
    }).catch((err) => {
      alert(err.message);
      console.log(err);
    });
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh', overflow: 'hidden' }}>
          <BannerMobile />
          {loading
            ? (
              <div className={classes.loading}>
                <img alt="loading" src={images.Loading} width="100" height="100" />

              </div>
            )
            : (
              <div className={classes.root}>
                <DateDayModal />
                <div className={classes.sectionTwo}>
                  <div className="profile-section">
                    <Container>
                      <Grid container alignItems="flex-start">
                        <Grid item xs={8}>
                          <div style={{ marginTop: 25 }}>
                            <div style={{ color: '#9e7666', fontSize: 28, fontWeight: '500' }}>
                              Hello,
                              {' '}
                              {user?.signupInfo?.firstName}
                              👋🏻
                            </div>
                            <div style={{
                              color: '#9e7666',
                              fontSize: 12,
                              fontWeight: '500',
                            }}
                            >
                              {gettingToKnowYouInfo?.headline}
                            </div>
                          </div>

                          <div style={{
                            display: 'flex',
                            marginTop: 16,
                            alignItems: 'center',
                            marginBottom: 25,
                          }}
                          >
                            <img
                              alt="you"
                              src={profilePicture || user.photoURL}
                              width="72"
                              height="85"
                              style={{
                                borderRadius: 50,
                                objectFit: 'cover',
                                border: '3px solid #fff',
                              }}
                            />
                            <div style={{
                              marginLeft: 10,
                              fontSize: 12,
                              color: '#555',
                              fontWeight: '500',
                            }}
                            >
                              <div role="button" onKeyDown={null} tabIndex={0}>
                                <div className="share-link" onClick={handleTo('/adhoc')}>
                                  {user?.creditBalance || 0}
                                  {' '}
                                  credits remaining
                                </div>
                                <div className="share-link" onClick={handleTo('/referral')}>
                                  {user?.completedReferralsCount || 0}
                                  {' '}
                                  friends referred
                                </div>
                              </div>
                              <div style={{ marginTop: 16, fontWeight: '700' }}>
                                Rating:
                                {' '}
                                {user1?.convoRateAvg || 0}
                                /5
                                {' '}
                                ⭐️
                                <img
                                  ref={anchorEl}
                                  onClick={() => setOpenTooltip(true)}
                                  src={images.Info}
                                  alt="info"
                                  style={{
                                    display: 'inline-block',
                                    height: 12,
                                    width: 12,
                                    position: 'relative',
                                    top: 1,
                                    left: 4,
                                  }}
                                />
                                <Popper
                                  anchorEl={anchorEl.current}
                                  disablePortal
                                  className={classes.grow}
                                  open={openTooltip}
                                  role={undefined}
                                  transition
                                  placement="bottom-start"
                                >
                                  {({ TransitionProps }) => (
                                    <Grow
                                      {...TransitionProps}
                                    >
                                      <div className={classes.popProfilCard}>
                                        <ClickAwayListener
                                          onClickAway={() => setOpenTooltip(false)}
                                        >
                                          <div style={{
                                            color: '#6f6c54',
                                            backgroundColor: '#ffffff',
                                            padding: 10,
                                            borderRadius: 8,
                                            marginRight: 20,
                                          }}
                                          >
                                            Enjoy better curations when you
                                            <br />
                                            create great experiences for others.
                                            <br />
                                            <br />

                                            How star ratings work:
                                            <br />
                                            <br />
                                            After each date, members have the opportunity to review the date,
                                            based on their conversational experience. Ratings are anonymous.
                                            You won’t see individual ratings tied to a particular date or person.
                                            <br />
                                            <br />
                                            This helps us better curate your experiences for our community.
                                          </div>
                                        </ClickAwayListener>
                                      </div>
                                    </Grow>
                                  )}
                                </Popper>
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ textAlign: 'right', marginTop: 20 }}>
                            <img alt="kopi-date" src={images.KopiDate} width="40" height="20" />
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                    <img
                      alt="kopi-date"
                      src={images.SocialClub}
                      width="80"
                      onClick={handleTo('/referral')}
                      style={{
                        position: 'absolute', bottom: 16, right: 16, zIndex: 1,
                      }}
                    />
                  </div>

                  {needToDoSomething()
                  && (
                  <div className="need-to-do">
                    <Container>

                      {
                            user?.userState === 1
                              ? <Action type="info" text1="Status:" text2="Application in review" noAct />

                              : user?.userState === 2 && hasConcludedDate()
                                ? <Action type="info" actText="Go for additional new date" handleClick={handleTo('/adhoc')} text2="Waiting for next date" />

                                : user?.userState === 2 && !user?.onboarded && !lastMatch
                                  ? <Action type="warning" actText="PROCEED" handleClick={handleTo('/payment')} text1="Pending:" text2="The Kopi Date Brew Onboarding" decor="underline" />

                                  : user?.userState === 2 && !user?.planType && user?.price === 1 && !user?.creditBalance
                                    ? <Action type="info" actText="SELECT" handleClick={handleTo('/subscription')} text2="Choose a plan that works for you!" />

                                    : !gettingToKnowYouInfo && user.userState === 3
                                      ? <Action type="warning" handleClick={handleTo('/knowing-the-real-you')} text1="Pending:" text2="Complete Profile" decor="underline" />

                                      : todaysMatch
                                        ? <Action type="success" text1="" text2="Woo hoo! It`s date-day! 🥳" actText="DETAILS" btnBg="orange" handleClick={handleTo('/upcoming-date')} />

                                        : user?.userState === 4
                                          ? <Action type="info" text1="Status:" text2="Waiting for curation" noAct />

                                          : (user?.userState === 5 && (nextMatch || todaysMatch))
                                            ? <Action type="warning" text1="Pending:" text2="Confirm date" decor="underline" actText="DETAILS" handleClick={handleTo('/date-details')} />

                                            : user?.planType && !user?.hasReadToolkit
                                              ? <Action type="info" text1="" text2="Read essential tool kit" handleClick={openGuide} actText="Read" />

                                              : (user?.userState === 6 && (nextMatch || todaysMatch))
                                                ? <Action type="info" text1="Status:" text2="Pending Partner's Confirmation" actText="DETAILS" handleClick={handleTo('/upcoming-date')} />

                                                : user?.userState === 7 && isUserNeverFillFeedback()
                                                  ? (
                                                    <Action
                                                      type="success"
                                                      text1="Status:"
                                                      text2="Date Confirmed"
                                                      actText="LOCATION REVEAL"
                                                      btnBg="orange"
                                                      handleClick={handleTo('/upcoming-date')}
                                                    />
                                                  )
                                                  : isUserNeverFillFeedback()
                                                    ? (
                                                      <Action
                                                        type="warning"
                                                        text1="Pending:"
                                                        text2="Conclude the date"
                                                        decor="underline"
                                                        handleClick={handleTo(`/conclude/${todaysMatch?.matchId || lastMatch.matchId}`)}
                                                        actText="CONCLUDE"
                                                      />
                                                    )
                                                    : null
                        }

                    </Container>
                  </div>
                  )}

                  <div className="actions-section">
                    <Container>
                      <div className="title">Actions</div>
                    </Container>
                    <div className="actions">
                      {Boolean(todaysMatch) && <img id="action-to-chat" onClick={handleTo('/chat')} className="action" alt="ETA" src={images.Eta} />}
                      {Boolean(todaysMatch) && <img id="action-to-kit" onClick={handleTo('/kit')} className="action" alt="Kit" src={images.Kit} />}
                      {isUserNeverFillFeedback()
                      && <img id="action-to-conclude" onClick={handleTo(`/conclude/${todaysMatch?.matchId || lastMatch.matchId}`)} alt="Conclude" className="action" src={images.Conclude} />}
                      {(user?.userState === 2 && !user?.onboarded && !lastMatch)
                      && <img id="action-to-boarded" onClick={handleRedirect('https://kopidate.com.au/user/welcome')} alt="Boarded" className="action" src={images.GetOnboarded} />}
                      <img id="action-to-referral" onClick={handleTo('/referral')} alt="Earn" className="action" src={images.Earn} />
                      {(!todaysMatch && !nextMatch)
                      && <img id="actions-to-availability" onClick={handleTo('/knowing-the-real-you#availability')} className="action" alt="Availability" src={images.Availability} />}
                      <img id="actions-to-share-feedback" onClick={handleTo('/share-feedback')} className="action" alt="Win" src={images.Win} />
                      {(!user.unlockQuote && lastMatch)
                      && <img id="actions-to-unlock-quote" onClick={handleToggleOpenConfirm} alt="Quote" className="action" src={images.Quote} style={{ marginTop: '-3%' }} />}
                    </div>
                  </div>
                </div>

                <ReferralBlock toggleOpenToShare={toggleOpenToShare} />
                <KitBlock />
                <Divider sx={{ border: '2px solid #f3f3f3', mt: 3 }} />

                <div hidden={env.recommendPeople === false}>
                  <Recommendation />
                  <Divider sx={{ border: '2px solid #f3f3f3', mt: 2 }} />
                </div>

                <div>
                  <QuizesAndGames />
                  <Divider sx={{ border: '2px solid #f3f3f3', mt: 2, mb: 1 }} />
                </div>

                <div hidden={env.understandYourself === false || user.userState === 1 || !user.userState}>
                  <UnderstandYourself />
                </div>
                <Divider sx={{ border: '2px solid #f3f3f3', mt: 1 }} />

                <div hidden={env.dessertForTheSoul === false}>
                  <Articles handleRedirect={handleRedirect} />
                  <Divider sx={{ border: '2px solid #f3f3f3', mt: 2 }} />
                </div>

                <MonthlyQuestions />

                <div>
                  <ExploreTopic />
                </div>

                <div>
                  <Divider sx={{ border: '2px solid #f3f3f3', mb: 1 }} />
                  <TikTok url="https://www.tiktok.com/@kopidate/video/7123123919195786498" />
                  <Divider sx={{ border: '2px solid #f3f3f3' }} />
                </div>

                <QuoteOfTheDate />

                <SwipeableDrawer
                  anchor="bottom"
                  open={openToShare || false}
                  onClose={toggleOpenToShare}
                >
                  <Box
                    sx={{ width: 'auto', textAlign: 'center' }}
                    role="presentation"
                  >
                    <div style={{
                      marginTop: 10,
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#000',
                    }}
                    >
                      Share using
                    </div>
                    <div style={{
                      padding: '18px 20px 40px',
                      display: 'flex',
                      gap: 4,
                      justifyContent: 'center',
                    }}
                    >
                      <WhatsappIcon size={45} round onClick={handleRedirect(`https://api.whatsapp.com/send?text=${message}`)} />
                      <TelegramIcon size={45} round onClick={handleRedirect(`https://t.me/share/url?url={https://kopidate.com.au}&text=${message}`)} />
                      <a href={`sms:&body=${message}`}>
                        <EmailIcon size={45} round />
                      </a>
                    </div>
                  </Box>
                </SwipeableDrawer>
                <BottomNav active={0} />
              </div>
            )}
        </Grid>
      </Grid>

      <ConfirmationModal open={confirmUnlock} title="Unlock Quote of the Date" desc="Are you sure?" handleClose={handleToggleOpenConfirm} handleAgree={openQuoteOfTheDate} />
    </>
  );
}

function Action({
  type, noAct, text1, text2, decor, actText, handleClick, btnBg,
}) {
  const c = type === 'warning' ? '#ffa634' : type === 'info' ? '#7ca6ef' : type === 'success' ? '#55b37e' : null;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <li style={{ display: 'list-item', color: c, fontSize: 14 }}>
        {text1}
        {' '}
        <strong style={{ textDecoration: decor, fontStyle: type === 'info' ? 'italic' : 'normal' }} onClick={handleClick || null}>{text2}</strong>
      </li>
      {!noAct
        && (
        <div>
          <button
            style={{
              background: btnBg || c, borderRadius: 25, border: 'none', color: '#fff', fontSize: 10, fontWeight: '500', padding: '4px 12px',
            }}
            onClick={handleClick}
          >
            {actText || 'GET STARTED'}
          </button>
        </div>
        )}
    </div>
  );
}
