/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import {
  // Box,
  Button,
  Chip, ClickAwayListener, Container, Divider, Grid, Grow, IconButton, Popper, TextField,
} from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { Link, useLocation } from 'react-router-dom';

import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import images from '../../Configs/images';
import { brown_light, grey } from '../../Configs/colors';
import {
  getGettingToKnowYou, getNextMatch, getTodaysMatch, getUserData, setUserData,
} from '../../utils/storage';
import { auth, firestore } from '../../Configs/firebase';
// import UpcomingDate from '../UpcomingDate/UpcomingDate';
import { amplytics } from '../../utils/helper';
import {isMobile} from 'react-device-detect';

export default function KnowingTheRealYou(props) {
  const user = getUserData();
  const gettingToKnowYouInfo = getGettingToKnowYou();
  const upcomingDate = getTodaysMatch() || getNextMatch();
  const { classes, history } = props;
  const [agreeSingle, setAgree] = useState(false);
  const [samples, setSamples] = useState(false);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    anyQuestionsForUs: '',
    headline: '',
    height: '',
    hobbiesQuestion: '',
    race: '',
    fridayEvening: false,
    saturdayAfternoon: false,
    saturdayMorning: false,
    sundayAfternoon: false,
    sundayMorning: false,
    thursdayEvening: false,
    unavailableQuestion: '',
    coffeePrefernce: '',
  });

  const availabilityRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#availability') {
      availabilityRef.current.scrollIntoView();
    }
  }, [location.hash]);

  useEffect(() => {
    document.title = 'Kopi Date Experience - Knowing the Real You';
    amplytics('KnowingTheRealYou View');
    
    if (isMobile) {
        window.location.assign(process.env.REACT_APP_DEEPLINK + `/gtky`)
    }
    
    if (gettingToKnowYouInfo) {
      setInput({ ...input, ...gettingToKnowYouInfo });
      setAgree(true);
      const newPersonalities = {};
      const newPassionates = {};
      Object.keys(gettingToKnowYouInfo).forEach((key) => {
        if (personalitiesOpt[key]) {
          newPersonalities[key] = gettingToKnowYouInfo[key];
        }
        if (passionsOpt[key]) {
          newPassionates[key] = gettingToKnowYouInfo[key];
        }
      });
      setPersonalities(newPersonalities);
      setPassionates(newPassionates);
    }
  }, []);
  // updated this to at least 3 checked
  const [error, setError] = useState({});
  const checkThereAreTwoAvailable = () => {
    const {
      fridayEvening,
      saturdayAfternoon,
      saturdayMorning,
      sundayAfternoon,
      sundayMorning,
      thursdayEvening,
    } = input;
    const availabilties = [
      fridayEvening,
      saturdayAfternoon,
      saturdayMorning,
      sundayAfternoon,
      sundayMorning,
      thursdayEvening,
    ];

    let truthy = 0;
    availabilties.forEach((val) => {
      if (val) truthy += 1;
    });
    return truthy >= 3;
  };
  const handleChangeError = () => {
    setError({
      single: !agreeSingle,
      headline: (input.headline?.split(' ')?.length < 3 || input.headline?.length > 80),
      height: !input.height,
      // coffeePrefernce: !input.coffeePrefernce,
      hobbiesQuestion: input.hobbiesQuestion?.length < 100,
      race: !input.race,
      personalities: Object.keys(personalities)?.length !== 5,
      passionates: Object.keys(passionates)?.length < 6,
      availability: !(
        input.fridayEvening
                || input.saturdayAfternoon
                || input.saturdayMorning
                || input.sundayAfternoon
                || input.sundayMorning
                || input.thursdayEvening),
    });
  };
  const anyError = () => (
    !agreeSingle
            || (input.headline?.split(' ')?.length < 3 || input.headline?.length > 80)
            || !input.height
            || input.hobbiesQuestion?.length < 100
            || !input.race
            // || !input.coffeePrefernce
            || Object.keys(personalities)?.length !== 5
            || Object.keys(passionates)?.length < 6
            || !(
              input.fridayEvening
                || input.saturdayAfternoon
                || input.saturdayMorning
                || input.sundayAfternoon
                || input.sundayMorning
                || input.thursdayEvening)
                || (input.unavailableQuestion?.length < 3 || (input.unavailableQuestion?.length === 3 && input.unavailableQuestion?.toUpperCase() !== 'NIL'))
            || !checkThereAreTwoAvailable()
  );

  const [personalities, setPersonalities] = useState({});
  const [passionates, setPassionates] = useState({});

  useEffect(() => {
    handleChangeError();
  }, [input, personalities, passionates]);

  const addPersonality = (personality) => () => {
    if (Object.keys(personalities)?.length < 5) {
      setPersonalities({ ...personalities, [personality]: true });
    }
  };

  const deletePersonality = (personality) => () => {
    const newInputs = input;
    delete newInputs[personality];
    setInput(newInputs);

    const newPersonalities = Object.keys(personalities).filter((p) => p !== personality);
    const newPersonalitiesInObj = {};
    newPersonalities?.forEach((np) => {
      newPersonalitiesInObj[np] = true;
    });
    setPersonalities(newPersonalitiesInObj);
  };

  const addPassionate = (passionate) => () => {
    setPassionates({ ...passionates, [passionate]: true });
  };

  const deletePassionate = (passionate) => () => {
    const newInputs = input;
    delete newInputs[passionate];
    setInput(newInputs);

    const newPassionates = Object.keys(passionates).filter((p) => p !== passionate);
    const newPassionatesInObj = {};
    newPassionates?.forEach((np) => {
      newPassionatesInObj[np] = true;
    });
    setPassionates(newPassionatesInObj);
  };

  const changeInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const changeInputNumber = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value?.replace(/[^0-9]/g, '') });
  };

  const handleToggleAvailability = (e) => {
    setInput({ ...input, [e.target.name]: !input[e.target.name] });
  };

  const handleChangeDrink = (e) => {
    setInput({ ...input, coffeePrefernce: e.target.name });
  };

  const handleSubmit = async () => {
    setLoading(true);
    // analytics('/btn-save-gtky');
    const err = await anyError();
    if (!err) {
      const docRef = doc(firestore, `/user/${auth.currentUser?.uid}`);

      const newGettingToKnowYouInfo = {
        ...input,
        ...personalities,
        ...passionates,
      };
      await updateDoc(docRef, {
        gettingToKnowYouInfo: newGettingToKnowYouInfo,
      }).then(() => {
        amplytics('Completed KnowingTheRealYou');
        setUserData(auth.currentUser);
        setLoading(false);
        history.replace('/');
      }).catch((e) => {
        setLoading(false);
        console.log(e);
      });
      amplytics('Tracking - Users filling up getting to know you info');
    }
  };

  const pauseCurationCondition = () => {
    const thereIsMonth = input.unavailableQuestion?.toLowerCase()?.match(/month/g);
    const thereIsWhole = input.unavailableQuestion?.toLowerCase()?.match(/whole/g);
    const more30Chars = input.unavailableQuestion?.length > 30;
    if (thereIsMonth || thereIsWhole || more30Chars) {
      setOpenTooltip(3);
    }
  };

  useEffect(() => {
    pauseCurationCondition();
  }, [input.unavailableQuestion]);

  const anchorEl = useRef(null);
  const anchorTiming = useRef(null);
  const anchorPauseCuration = useRef(null);
  const [openTooltip, setOpenTooltip] = useState(0);

  const [values, setValues] = useState(
    [1, 2, 3].map((number) => new DateObject().set({
      day: number,
    })),
  );

  useEffect(() => {
    if (values.length) {
      let text = 'I unvailable on ';
      values.forEach((val) => {
        const timing = new Date(val.unix * 1000);
        text += `${timing.toDateString()}, `;
      });

      setInput({ ...input, unavailableQuestion: text });
    }
  }, [values]);

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        { loading
          ? (
            <div className={classes.loading}>
              <div className="loadingText">Please wait...</div>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
          ) : (
            <div className={classes.root}>
              <Container>
                <IconButton className="back" onClick={() => history.replace('/')}><ChevronLeftRounded /></IconButton>
                <div className="section">
                  <div className="title">
                    Welcome
                    {' '}
                    {user?.signupInfo?.firstName}
                  </div>
                  <div className="desc">Knowing the real you</div>
                  <div style={{ display: 'flex', marginTop: 20, gap: 8 }}>
                    <div>
                      <img alt="kopi date" src={images.Idea} style={{ width: 28, height: 28 }} />
                    </div>
                    <div className="info">
                      It takes just 5 mins. You may fill this up later.
                      {' '}
                      <Link to="/" className="info">Skip and continue.</Link>
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div className="title2">STEP 1</div>
                  <div style={{ marginTop: 16 }}>
                    <CheckBox
                      checked={agreeSingle}
                      toggleCheck={() => setAgree(!agreeSingle)}
                      labelFor="check1"
                      labelN={(
                        <span>
                          <strong>I acknowledge that Kopi Date is for singles only</strong>
                          {' '}
                          (i.e. unmarried and not seeing anyone)
                        </span>
                      )}
                    />
                  </div>
                  <img src={images.LookingFor} alt="getting to know you" style={{ width: '100%', maxHeight: 200, marginTop: 16 }} />

                  <div style={{ textAlign: 'left' }}>
                    <div>
                      <div className="form-label">Ethnicity</div>
                      <TextField name="race" value={input.race} onChange={changeInput} size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                    </div>
                    <div>
                      <div className="form-label">Height in cm</div>
                      <TextField name="height" value={input.height} onChange={changeInputNumber} size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div className="title2">THE FUN PART</div>
                  <div className="info2">Go beneath the surface for us to know the real you. Answer the questions to unlock better connections!</div>
                  <div style={{ textAlign: 'left' }}>
                    <div>
                      <div className="form-label">Choose 5 adjectives to describe your personality?</div>
                      <div className="order-desc">Select from the list below</div>
                      <div className="chips-selected">
                        <div style={{ display: 'flex', gap: 6, flexWrap: 'wrap' }}>
                          {Object.keys(personalities).map((personality) => (
                            <Chip
                              onClick={deletePersonality(personality)}
                              label={personalitiesOpt[personality]}
                              key={personality}
                              className="chip-selected"
                            />
                          ))}
                        </div>
                      </div>
                      <div className="title3">Select adjectives</div>
                      <div className="chips-options">
                        {Object.keys(personalitiesOpt).map((personality) => (
                          <Chip
                            onClick={addPersonality(personality)}
                            label={personalitiesOpt[personality]}
                            key={personalitiesOpt[personality]}
                            className="chip"
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div style={{ textAlign: 'left' }}>
                    <div>
                      <div className="form-label">What are you passionate about?</div>
                      <div className="order-desc">Choose 6 or more options below on areas that you enjoy sharing or having a conversation about!</div>
                      <div className="chips-selected">
                        <div style={{ display: 'flex', gap: 6, flexWrap: 'wrap' }}>
                          {Object.keys(passionates).map((passionate) => (
                            <Chip
                              onClick={deletePassionate(passionate)}
                              label={passionsOpt[passionate]}
                              key={passionate}
                              className="chip-selected"
                            />
                          ))}
                        </div>
                      </div>
                      <div className="title3">I can have a conversation about...</div>
                      <div className="chips-options">
                        {Object.keys(passionsOpt).map((passionate) => (
                          <Chip
                            onClick={addPassionate(passionate)}
                            label={passionsOpt[passionate]}
                            key={passionsOpt[passionate]}
                            className="chip"
                          />
                        ))}
                      </div>
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div style={{ textAlign: 'left' }}>
                    <div>
                      <div className="form-label">Use 3 words to craft a title about you</div>
                      <div className="order-desc">If there was a newspaper article written about you, what would its headline be? Feeling Stuck? Look at some of our samples below!</div>
                      <TextField name="headline" value={input.headline} onChange={changeInput} placeholder="Be creative! Tip: ask a friend!" size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                    </div>
                  </div>

                  <Divider className="divider" />

                  <div style={{ textAlign: 'left' }}>
                    <div>
                      <div className="form-label">Write a short description of yourself</div>
                      <div className="order-desc">Let us know the essence of who you are and the must-knows about you! Don’t be afraid to bring out the uniqueness in you!</div>
                      <TextField name="hobbiesQuestion" value={input.hobbiesQuestion} onChange={changeInput} multiline rows={4} placeholder="Passion, hobbies, quirks, perspectives. See below for inspiration!" size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                    </div>

                    <div style={{ marginTop: 16 }}>
                      <div className="title2">SAMPLES</div>
                      <div style={{
                        backgroundColor: '#fff', borderRadius: 12, padding: '14px 16px', marginTop: 16,
                      }}
                      >
                        <div style={{ display: 'flex', gap: 8 }}>
                          <div>
                            <img alt="example" src={images.Example1} style={{ width: 40, maxHeight: 85 }} />
                          </div>
                          <div>
                            <div style={{ color: brown_light, fontSize: 12, fontWeight: 'bold' }}>EAGER ATHLETIC NERD</div>
                            <div style={{ color: '#000', fontSize: 12, margin: '12px 0' }}>
                              A fresh Economics graduate that enjoys rugby and soccer.
                              I am eager to open my eyes to different worlds,
                              both physically and mentally.
                              So I love traveling and am a secret literature nerd as well.
                            </div>
                          </div>
                        </div>

                        {samples
                                        && (
                                        <>
                                          <Divider className="divider" />

                                          <div style={{ display: 'flex', gap: 8 }}>
                                            <div>
                                              <img alt="example" src={images.Example2} style={{ width: 40, maxHeight: 85 }} />
                                            </div>
                                            <div>
                                              <div style={{ color: brown_light, fontSize: 12, fontWeight: 'bold' }}>PASSIONATE FOOD-LOVING ACTIVIST</div>
                                              <div style={{ color: '#000', fontSize: 12, margin: '12px 0' }}>
                                                A food critic that is keen to try anything so
                                                long it is edible. I enjoy volunteering and
                                                use my bakeshop to support social issues
                                                I`m passionate about. It brings me joy to
                                                brighten the day of those around me with my
                                                energy and zest for life.
                                              </div>
                                            </div>
                                          </div>

                                          <Divider className="divider" />

                                          <div style={{ display: 'flex', gap: 8 }}>
                                            <div>
                                              <img alt="example" src={images.Example3} style={{ width: 40, maxHeight: 85 }} />
                                            </div>
                                            <div>
                                              <div style={{ color: brown_light, fontSize: 12, fontWeight: 'bold' }}>ADVENTUROUS BUDDING ENTREPRENEUR  </div>
                                              <div style={{ color: '#000', fontSize: 12, margin: '12px 0' }}>
                                                An entrepreneur who aims to increase the quality
                                                and accessibility of education using technology.
                                                I would like to think I am adventurous and
                                                spontaneous. Apart from enjoying thrilling
                                                experiences from horror movies to extreme sports,
                                                I am also always on the lookout for opportunities
                                                to have fun and meet interesting people.
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        )}
                        <div
                          style={{
                            textAlign: 'center', textDecoration: 'underline', color: grey, fontSize: 12,
                          }}
                          onClick={() => setSamples(!samples)}
                        >
                          VIEW
                          {' '}
                          {samples ? 'LESS' : 'MORE'}
                        </div>
                      </div>
                    </div>

                    <div
                      ref={availabilityRef}>
                      <Divider className="divider" />
                      <div className="flex space-between" style={{ alignItems: 'center', marginBottom: 4 }}>
                        <div className="form-label">Availability over the next 1 month</div>
                        <div
                          style={{
                            color: '#000',
                            fontWeight: '300',
                            fontSize: 12,
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                          }}
                          onClick={() => setOpenTooltip(1)}
                        >
                          Need Help?
                        </div>
                      </div>
                      <div className="order-desc">Maximise the quality of your Kopi Date curations by checking more slots ☕</div>
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Saturday Morning</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 10AM - 1PM
                          </div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox disabled={upcomingDate && !upcomingDate.askForReschedule} toggleCheck={handleToggleAvailability} checked={input.saturdayMorning} labelFor="saturdayMorning" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Saturday Afternoon</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 2PM - 6PM
                          </div>
                          <div ref={anchorEl} style={{ marginTop: 4 }}>
                            <CheckBox disabled={upcomingDate && !upcomingDate.askForReschedule} toggleCheck={handleToggleAvailability} checked={input.saturdayAfternoon} labelFor="saturdayAfternoon" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                      </Grid>
                      <Divider className="divider" />
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Sunday Morning</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 10AM - 1PM
                          </div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox disabled={upcomingDate && !upcomingDate.askForReschedule} toggleCheck={handleToggleAvailability} checked={input.sundayMorning} labelFor="sundayMorning" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Sunday Afternoon</div>
                          <div style={{ fontSize: 10, color: grey }}>
                            Scheduled between 2PM - 6PM
                          </div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox disabled={upcomingDate && !upcomingDate.askForReschedule} toggleCheck={handleToggleAvailability} checked={input.sundayAfternoon} labelFor="sundayAfternoon" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                      </Grid>
                      {/* <Divider className="divider" />
                      <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Thursday Evening</div>
                          <div style={{ fontSize: 10, color: grey }}>7PM onwards</div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.thursdayEvening || false} labelFor="thursdayEvening" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div style={{ fontSize: 12, fontWeight: 'bold', color: '#000' }}>Friday Evening</div>
                          <div style={{ fontSize: 10, color: grey }}>7PM onwards</div>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleToggleAvailability} checked={input.fridayEvening} labelFor="fridayEvening" size="small" color={brown_light} labelN={<strong>I&apos;M AVAILABLE</strong>} />
                          </div>
                        </Grid>
                      </Grid> */}
                    </div>

                    <div>
                      <Divider className="divider" />
                      <div className="form-label">Indicate your strictly unavailable date and timing (If any)</div>
                      <div ref={anchorPauseCuration} className="order-desc">As an exception to the schedule you have ticked above</div>

                      {/* unavailability */}
                      <div style={{ textAlign: 'center' }}>
                        <DatePicker
                          value={values}
                          onChange={setValues}
                          format="DD/MM/YYYY"
                          disabled={upcomingDate && !upcomingDate.askForReschedule}
                          multiple
                          sort
                          plugins={[
                            <DatePanel markFocused />,
                          ]}
                          style={{ widht: 400, marginTop: 8 }}
                        />
                      </div>

                      <TextField  disabled={upcomingDate && !upcomingDate.askForReschedule} ref={anchorTiming} name="unavailableQuestion" value={input.unavailableQuestion} onChange={changeInput} placeholder="Unavailable on 29 Jan, 4pm onwards" size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                      <Divider className="divider" />
                    </div>
                    <div>
                      <div className="form-label">Do you have any questions for us?</div>
                      <div className="order-desc">Skip this if you have none.</div>
                      <TextField name="anyQuestionsForUs" value={input.anyQuestionsForUs} onChange={changeInput} placeholder="Optional" size="medium" variant="outlined" fullWidth sx={{ background: '#fff', border: 'none', mt: 1 }} />
                    </div>
                    <div hidden>
                      <div className="form-label">Coffee Preference</div>
                      <div className="order-desc">Enjoy freshly brewed hot coffee to be served on date day.</div>
                      <Grid container sx={{ mt: 1 }}>
                        <Grid item xs={4}>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleChangeDrink} labelFor="Latte" checked={input.coffeePrefernce === 'Latte'} size="small" color={brown_light} labelN={<strong>Latte</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleChangeDrink} labelFor="Flat White" checked={input.coffeePrefernce === 'Flat White'} size="small" color={brown_light} labelN={<strong>Flat White</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleChangeDrink} labelFor="Non-caffeinated" checked={input.coffeePrefernce === 'Non-caffeinated'} size="small" color={brown_light} labelN={<strong>Non-caffeinated</strong>} />
                          </div>
                          <div style={{ fontSize: 10, color: grey, height: 14 }}>
                            (i.e. Hot chocolate, slow pressed
                            juices.) Our team will reach out
                            to you for more information.
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleChangeDrink} labelFor="Long Black" checked={input.coffeePrefernce === 'Long Black'} size="small" color={brown_light} labelN={<strong>Long Black</strong>} />
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div style={{ marginTop: 4 }}>
                            <CheckBox toggleCheck={handleChangeDrink} labelFor="Cappuccino" checked={input.coffeePrefernce === 'Cappuccino'} size="small" color={brown_light} labelN={<strong>Cappuccino</strong>} />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                </div>
                <div style={{ fontSize: 12, color: 'salmon', textAlign: 'center' }}>
                  {error.single && (
                  <div style={{ marginTop: 8 }}>
                    Please indicate that you’re single!
                  </div>
                  )}
                  {/* {error.coffeePrefernce && (
                  <div style={{ marginTop: 8 }}>
                    Please choose your drink preference
                  </div>
                  )} */}
                  {error.height && (
                  <div style={{ marginTop: 8 }}>
                    Please indicate your height!
                  </div>
                  )}
                  {error.availability
                  && <div style={{ marginTop: 8 }}>Please indicate your availability!</div>}
                  {!checkThereAreTwoAvailable()
                  && (
                  <div style={{ marginTop: 8 }}>
                    Please check at least 3 timings to proceed!

                  </div>
                  )}
                  {error.race
                  && <div style={{ marginTop: 8 }}>Please indicate your race!</div>}
                  {error.headline
                  && (
                  <div style={{ marginTop: 8 }}>
                    Please share your 3 words description! (max 80 chars)

                  </div>
                  )}
                  {error.hobbiesQuestion
                  && (
                  <div style={{ marginTop: 8 }}>
                    Tell us more about yourself (min of 100 chars),
                    this will largely inform your curation!

                  </div>
                  )}
                  {error.personalities
                  && (
                  <div style={{ marginTop: 8 }}>
                    Kindly ensure that you have 5 personalities :)

                  </div>
                  )}
                  {error.passionates
                  && (
                  <div style={{ marginTop: 8 }}>
                    Kindly ensure that you have at least 6 topics of interest :)

                  </div>
                  )}
                  {(input.unavailableQuestion?.length < 3 || (input.unavailableQuestion?.length === 3 && input.unavailableQuestion?.toUpperCase() !== 'NIL')) && <div style={{ marginTop: 8 }}>Please put &quot;NIL&quot; in the unavailability field if you&apos;re available for all the date selections!</div>}
                </div>
                <button disabled={anyError()} className={`submit ${anyError() && 'disabled'}`} onClick={handleSubmit}>
                  <div className="submit-text">SAVE & CONTINUE</div>
                </button>
              </Container>
            </div>
          )}
        <Popper
          anchorEl={anchorPauseCuration.current}
          disablePortal
          className={classes.grow}
          open={openTooltip === 3}
          role={undefined}
          transition
          placement="top-start"
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <div>
                <ClickAwayListener onClickAway={() => setOpenTooltip(0)}>
                  <div className={classes.tooltip}>
                    <div>
                      <b>Gentle note:</b>
                    </div>
                    <br />
                    <div>
                      Away for a month?
                      <br />
                      Pause curation here!
                    </div>
                    <br />
                    <Button
                      style={{ fontSize: 12 }}
                      color="secondary"
                      onClick={() => history.push('/settings', { pauseCuration: true })}
                    >
                      <span style={{ color: '#606060' }}>Proceed</span>
                    </Button>
                    <Button
                      sx={{ fontSize: 12 }}
                      onClick={() => setOpenTooltip(0)}
                    >
                      Close
                    </Button>
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
        <Popper
          anchorEl={openTooltip === 1 ? anchorEl.current : anchorTiming.current}
          disablePortal
          // className={classes.grow}
          open={[1, 2].includes(openTooltip)}
          role={undefined}
          transition
          placement="bottom-start"
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <div className={classes.popProfilCard}>
                <ClickAwayListener onClickAway={() => setOpenTooltip(0)}>
                  <div className={classes.tooltip}>
                    <div>
                      <b>
                        Step
                        {' '}
                        {openTooltip}
                      </b>
                    </div>
                    <br />
                    <div hidden={openTooltip !== 1}>
                      Select weekly time slots that you&apos;ll
                      <br />
                      mostly be available on (minimum 2).
                      <br />
                      Please choose as many as possible
                      <br />
                      to maximise the quality of your curation.
                    </div>
                    <div hidden={openTooltip !== 2}>
                      Indicate exceptions for the next 1 month
                      <br />
                      (if any), try to be as detailed as possible
                      <br />
                      (i.e. Not available on the 15th Apr 3-4pm).
                      <br />
                      For rare occasions if you&apos;re only available
                      <br />
                      on 1/2 days please indicate: only available
                      <br />
                      on X/Y here too.
                    </div>
                    <br />
                    <Button
                      sx={{ fontSize: 12 }}
                      onClick={() => setOpenTooltip(openTooltip === 2 ? 0 : 2)}
                    >
                      Ok, Got It
                    </Button>
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

function CheckBox({
  labelN, checked, toggleCheck, labelFor, color, size, disabled=false
}) {
  const small = size === 'small';
  return (
    <div style={{
      display: 'flex', alignItems: 'flex-start', textAlign: 'left', gap: 8,
    }}
    >
      <div>
        <input
          name={labelFor}
          onChange={toggleCheck}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          id={labelFor}
          style={{
            margin: 0, border: '1px solid #9e7666 !important', height: small ? 16 : 20, width: small ? 16 : 20, marginTop: 4,
          }}
        />
      </div>
      <div>
        <label htmlFor={labelFor} style={{ color: color || '#606060', fontSize: small ? 12 : 14 }}>{labelN}</label>
      </div>
    </div>
  );
}

const personalitiesOpt = {
  extroverted: 'Extroverted',
  driven: 'Driven',
  funny: 'Funny',
  independent: 'Independent',
  friendly: 'Friendly',
  ambivert: 'Ambivert',
  introvert: 'Introvert',
  extrovert: 'Extrovert',
  adventurous: 'Adventurous',
  sporty: 'Sporty',
  conservative: 'Conservative',
  ambitious: 'Ambitious',
  outgoing: 'Outgoing',
  easygoing: 'Easygoing',
  openMinded: 'open-minded',
  foodie: 'Foodie',
  straightforward: 'Straightforward',
  freeSpirited: 'Free-spirited',
  disciplined: 'Disciplined',
  familyOriented: 'Family-oriented',
  perfectionist: 'Perfectionist',
  practical: 'Practical',
  empathic: 'Empathic',
  passionate: 'Passionate',
  jovial: 'Jovial',
  laidBack: 'Laid Back',
  adaptable: 'Adaptable',
  organised: 'Organised',
  philosophical: 'Philosophical',
  diligent: 'Diligent',
  observant: 'Observant',
  creative: 'Creative',
  fashionable: 'Fashionable',
  thinker: 'Thinker',
  liberal: 'Liberal',
  courageous: 'Courageous',
  bubbly: 'Bubbly',
  compassionate: 'Compassionate',
  humorous: 'Humorous',
  sincere: 'Sincere',
  fun: 'Fun',
};

const passionsOpt = {
  books: 'Books',
  films: 'Films',
  food: 'Food',
  dancing: 'Dancing',
  singing: 'Singing',
  workout: 'Workout',
  photography: 'Photography',
  travel: 'Travel',
  cooking: 'Cooking',
  gaming: 'Gaming',
  cafeBarHopping: 'Cafe Bar Hopping',
  hangWithFriends: 'Hang With Friends',
  shopping: 'Shopping',
  writing: 'Writing',
  volunteering: 'Volunteering',
  artDiy: 'Art Diy',
  drinking: 'Drinking',
  museum: 'Museum',
  gardening: 'Gardening',
  yoga: 'Yoga',
  hiking: 'Hiking',
  culture: 'Culture',
  politics: 'Politics',
  science: 'Science',
  technology: 'Technology',
  philosophy: 'Philosophy',
  businessEconomics: 'Business Economics',
  education: 'Education',
  environment: 'Environment',
  socialIssues: 'Social Issues',
  religionSpirituality: 'Religion Spirituality',
  fashion: 'Fashion',
  history: 'History',
  exploring: 'Exploring',
  sports: 'Sports',
  music: 'Music',
};
