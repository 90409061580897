/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement, Elements, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { ChevronLeftRounded } from '@mui/icons-material';
import images from '../../Configs/images';
import { BottomNav } from '../../Elements';
import { auth, fFunction } from '../../Configs/firebase';
import { getUserData } from '../../utils/storage';
import { amplytics } from '../../utils/helper';

const stripeKey = process.env.REACT_APP_stripeKey;
const stripePromise = loadStripe(stripeKey);

export default function Component({ classes, history }) {
  const [payment, setPayment] = useState(0);
  const user = getUserData();

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className={classes.root}>
          <Container>

            <div hidden={payment !== 0}>
              <img src={images.Cafeimage} alt="cafeimage" style={{ width: '100%' }} />
              <div
                className="center"
                style={{
                  marginTop: -60,
                  background: '#fff',
                  overflow: 'hidden',
                  position: 'relative',
                  zIndex: 2,
                  borderRadius: '25px 25px 0 0',
                  paddingTop: 20,
                }}
              >
                <div className="head star">
                  <img src={images.Star} alt="star" width="20" height="20" />
                  Popular choice!
                </div>
                <img src={images.Adhoc} style={{ width: '25%', margin: '20px 0' }} alt="adhoc" />
                <div className="head2">Ad-Hoc Kopi Date</div>
                {user.price === 2 && <div className="price">$25.55</div>}
                {(user.price !== 2 && user.signupInfo?.gender === 'Male') && <div className="price">$45</div>}
                {(user.price !== 2 && user.signupInfo?.gender === 'Female') && <div className="price">$40</div>}
                <div className="desc">per credit</div>
              </div>

              <div style={{ marginTop: 20 }}>
                <Container>
                  <div className="benefit">
                    <img src={images.Tick} alt="tick" height="28" width="28" />
                    <div className="desc">
                      Access to
                      {' '}
                      <strong>1 Expedited Kopi Date Experience</strong>
                    </div>
                  </div>
                  <div className="benefit">
                    <img src={images.Tick} alt="tick" height="28" width="28" />
                    <div className="desc">
                      A
                      {' '}
                      <strong>white glove service</strong>
                      {' '}
                      from the entire Kopi Date team
                    </div>
                  </div>
                </Container>
              </div>
              <div style={{ marginTop: 40 }}>
                <button className="btn" onClick={() => setPayment(1)}>
                  PROCEED
                </button>
              </div>
            </div>

            <div hidden={payment !== 1}>
              <Elements stripe={stripePromise}>
                <CheckoutForm setPayment={setPayment} classes={classes} history={history} />
              </Elements>
            </div>

          </Container>
        </div>
        <BottomNav active={3} />
      </Grid>
    </Grid>
  );
}

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [cardVal, setCardVal] = useState(null);
  const [error, setError] = useState('');
  useEffect(() => {
    if (!cardVal) {
      const c1 = elements?.getElement(CardElement);
      const c2 = elements?.getElement('card');
      setCardVal(c1 || c2);
    }
  });
  const { classes, history, setPayment } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    setError('');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setLoading(false);
      return;
    }

    const callable = httpsCallable(fFunction, 'oneTimePaymentWrapper');
    await callable({ userId: auth.currentUser?.uid }).then(async (intents) => {
      const { paymentIntent, setupIntent } = intents.data;
      console.log('client_secret', paymentIntent.client_secret);
      await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: { card: cardVal },
      }).then(async (result) => {
        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          setError(result.error.message);
          setLoading(false);
        } else {
          console.log('payment success');
          amplytics('Purchased Ad Hoc');
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          setLoading(false);
          history.replace('/dates');
        }

        if (setupIntent) {
          await stripe
            .confirmCardSetup(setupIntent.client_secret, {
              payment_method: { card: cardVal },
            })
            .then(async (res) => {
              if (res.error) {
                setError(res.error.message);
                setLoading(false);
              } else {
                console.log('payment success');
                amplytics('Purchased Ad Hoc');
                setLoading(false);
                history.replace('/dates');
              }
            });
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    });
  };

  return (
    <form
      action="/charge"
      method="post"
      onSubmit={handleSubmit}
      style={{
        width: '100%',
        minHeight: '90vh',
        textAlign: 'center',
        paddingTop: 20,
      }}
    >
      {loading
          && (
          <div className={classes.loading2}>
            <div className="loadingText">Please wait...</div>
            <img alt="loading" src={images.Loading} width="100" height="100" />
          </div>
          )}
      <div
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
        }}
        className="center"
      >
        <div className="flex align-items-center" onClick={() => setPayment(0)}>
          <ChevronLeftRounded />
          <div>BACK</div>
        </div>

        <div className="center" style={{ marginTop: 24 }}>
          <img src={images.Adhocdate} style={{ width: '80%' }} alt="adhocdate" />

          <div className="head star" style={{ marginTop: 8 }}>
            <img src={images.Star} alt="star" width="20" height="20" />
            Popular choice!
          </div>

          <div className="head2" style={{ marginTop: 16 }}>Ad-Hoc Kopi Date</div>
          <Container sx={{ my: 2, color: '#606060' }}>
            <div>
              A thoughtful experience designed for
              genuine connections in person.
            </div>
          </Container>
        </div>

        <div style={{ width: '100%', marginTop: 42, marginBottom: 32 }}>
          <CardElement className="field-pay" id="card" />
        </div>
        {Boolean(error) && (
          <div style={{
            fontSize: 12,
            color: 'salmon',
            marginBottom: 20,
            fontWeight: 'bold',
          }}
          >
            {error}
          </div>
        )}
        <div className="center" style={{ marginTop: 20 }}>
          <img alt="stripe" src={images.Stripe} height="40" width="160" />
          <div style={{
            fontSize: 10,
            color: 'grey',
          }}
          >
            All transactions are secure and encrypted.
          </div>
        </div>
        <button
          type="submit"
          style={{
            cursor: 'pointer',
            background: '#FFA500',
            marginBottom: 8,
            border: 'none',
            borderRadius: 10,
            width: '100%',
            padding: '12px 14px',
            marginTop: 24,
          }}
        >
          <div style={{
            textAlign: 'center',
            width: '100%',
            fontWeight: 'bold',
            fontSize: 16,
            color: '#fff',
          }}
          >
            ONWARDS
          </div>
        </button>
      </div>
    </form>
  );
}
