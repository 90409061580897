/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box, Container, Grid, TextField,
} from '@mui/material';
import { Facebook } from '@mui/icons-material';
import {
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  // sendPasswordResetEmail,
} from '@firebase/auth';
import images from '../../Configs/images';
import { auth, FacebookProvide, GoogleProvide } from '../../Configs/firebase';
import { analytics, amplytics } from '../../utils/helper';
import { clearStorage, setLoginPlatformType, setUserId } from '../../utils/storage';
import { BaseModal } from '../../Elements';
import BannerMobile from '../../Fragments/BannerMobile';

export default function Login({ classes }) {
  const isMobile = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      userAgent.toLowerCase(),
    );
  };

  useEffect(() => {
    document.title = 'Kopi Date Experience - Login';
    getRedirectResult()
      .then((val) => {
        clearStorage();
        console.log(val);
        analytics(val.user.uid);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const LoginGoogleUsingRedirect = () => {
    signInWithRedirect(auth, GoogleProvide);
    setLoginPlatformType('google.com');
    amplytics('Tracking Users - Logged In with Google Account');
  };

  const handleGoToKopiDate = () => {
    window.open('https://kopidate.com.au/signin', '_blank');
  };

  const loginFBUsingPopUp = () => {
    signInWithPopup(auth, FacebookProvide)
      .then((val) => {
        clearStorage();
        console.log(val);
        analytics(val.user.uid);
        setLoginPlatformType('facebook.com');
        amplytics('Tracking Users - Logged In with Facebook Account');
        setUserId(val.user.uid);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loginFBUsingRedirect = () => {
    signInWithRedirect(auth, FacebookProvide);
    setLoginPlatformType('facebook.com');
    amplytics('Tracking Users - Logged In with Facebook Account');
  };
  const handleLoginWithFB = () => {
    if (isMobile()) loginFBUsingRedirect();
    else loginFBUsingPopUp();
  };

  const LoginGoogleUsingPopUp = () => {
    signInWithPopup(auth, GoogleProvide)
      .then((val) => {
        clearStorage();
        console.log(val);
        analytics(val.user.uid);
        setLoginPlatformType('google.com');
        amplytics('Tracking Users - Logged In with Google Account');
        setUserId(val.user.uid);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleLoginWithGoogle = () => {
    LoginGoogleUsingPopUp();
    // if (isMobile()) LoginGoogleUsingRedirect();
    // else LoginGoogleUsingPopUp();
  };

  const [useEP, setUseEP] = useState(false);
  const [fields, setFields] = useState({
    email: '',
    password: '',
  });
  const [fieldErr, setFieldErr] = useState('');

  const handleToggleUseEP = () => {
    setUseEP(!useEP);
  };

  const handleChangeField = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const SignupUsingEP = () => {
    createUserWithEmailAndPassword(auth, fields.email, fields.password)
      .then((val) => {
        sendEmailVerification(val.user);
        LoginUsingEP();
      })
      .catch((err) => {
        setFieldErr(err.code);
      });
  };

  const LoginUsingEP = () => {
    setFieldErr('');
    signInWithEmailAndPassword(auth, fields.email, fields.password)
      .then((val) => {
        clearStorage();
        console.log(val);
        analytics(val.user.uid);
        setLoginPlatformType('emailPwd');
        amplytics('Tracking Users - Logged In with Email Authentication');
        setUserId(val.user.uid);
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 'auth/user-not-found') {
          setLoginPlatformType('emailPwd');
          SignupUsingEP();
          amplytics('Tracking - Signed Up Users');
        } else {
          setFieldErr(err.code);
        }
      });
  };

  // const [resetLinkSent, setResetLinkSent] = useState(false);

  // const resetPass = () => {
  //   sendPasswordResetEmail(auth, fields.email)
  //     .then(() => {
  //       setResetLinkSent(true);
  //       setFieldErr(false);
  //     });
  // };

  return (
    <div className={classes.root}>
      <BannerMobile />
      <Grid container justifyContent="center">
        <Grid
          item
          lg={4}
          md={6}
          sm={8}
          xs={12}
        >
          <Container className="container">
            <div style={{ textAlign: 'center', marginTop: 16 }}>
              <img
                alt="kopi-date"
                src={images.KopiDate}
                width="60"
                height="30"
              />
            </div>
            <div style={{ textAlign: 'center', marginTop: 12 }}>
              <img
                alt="kopi-date"
                src={images.Welcome}
                style={{ width: '80%', height: '100%' }}
              />
              <div className="title">
                <div>Welcome to</div>
                <div>Kopi Date</div>
              </div>
              <div style={{ fontSize: 14, color: '#606060', marginTop: 8 }}>
                Log in to access your Kopi Date experience
              </div>
              <div
                style={{ fontSize: 14, fontWeight: 'bold', margin: '14px 0' }}
              >
                Log in below
              </div>
              <button
                id="btn-login-fb"
                onClick={handleLoginWithFB}
                variant="contained"
                className="btn"
              >
                <Facebook sx={{ color: '#4267B2', fontSize: 28 }} />
                <div className="btn-text">
                  Continue with Facebook
                </div>
              </button>
              <button
                id="btn-login-google"
                onClick={handleLoginWithGoogle}
                variant="contained"
                className="btn"
              >
                <img alt="google" src={images.Google} width="24" height="24" />
                <div className="btn-text">
                  Continue with Google
                </div>
              </button>
              <button
                id="btn-login-email"
                onClick={handleToggleUseEP}
                variant="contained"
                className="btn"
              >
                <img alt="google" src={images.Email} width="24" height="24" />
                <div className="btn-text">
                  Continue with Email
                </div>
              </button>
              <button
                id="btn-signup-KD"
                onClick={handleGoToKopiDate}
                variant="contained"
                className="btn-signup"
              >
                <div className="btn-text">
                  Sign up Free
                </div>
              </button>
              <a
                id="btn-need-help"
                href="mailto:hello@kopidate.com?subject=Hello"
                target="_blank"
                className="help"
                rel="noreferrer"
              >
                Need help?
              </a>
            </div>
          </Container>
        </Grid>
      </Grid>
      <BaseModal open={useEP} handleSubmit={LoginUsingEP} title="Sign In" handleClose={handleToggleUseEP} submitText="Continue">
        <Box>
          <TextField
            error={Boolean(fieldErr)}
            onChange={handleChangeField}
            value={fields.email}
            name="email"
            fullWidth
            variant="outlined"
            size="small"
            type="email"
            label="Email"
            sx={{ my: 2 }}
          />
          <TextField
            error={Boolean(fieldErr)}
            onChange={handleChangeField}
            value={fields.password}
            name="password"
            fullWidth
            variant="outlined"
            size="small"
            type="password"
            label="Password"
            helperText={fieldErr}
          />
          {/* {fieldErr
          && <Button onClick={resetPass}>Reset Password</Button>}
          {resetLinkSent
          && <div>Please check your email to reset your password</div>} */}
        </Box>
      </BaseModal>
    </div>
  );
}
