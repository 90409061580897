/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ChevronLeftRounded } from '@mui/icons-material';
import { Grid, IconButton, Snackbar } from '@mui/material';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
// import { DatePicker, LocalizationProvider, MuiPickersAdapter } from '@mui/lab'
import { doc, updateDoc } from 'firebase/firestore';
import { getUserData } from '../../utils/storage';
import { auth, firestore } from '../../Configs/firebase';

export default function Calendly(props) {
  const { classes, history } = props;
  const userData = getUserData();
  const [snack, setSnack] = useState('');

  useEffect(() => {
    document.title = 'Kopi Date Experience - Calendly';
  }, []);

  useCalendlyEventListener({
    onEventScheduled: async () => {
      // console.log(userData);
      const docRef = doc(firestore, `/userPrivateData/${auth.currentUser.uid}`);
      await updateDoc(docRef, {
        scheduledCall: true,
      }).then(() => {
        console.log('suc');
      }).catch((err) => {
        console.log('err', err);
      });
    },
  });

  const handleBack = (e) => {
    e.preventDefault();
    history.replace('/');
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh' }}>
        <div className={classes.root}>
          <IconButton className="back" onClick={handleBack}><ChevronLeftRounded /></IconButton>

          <div className="top">
            <div className="title">
              Hello
              {' '}
              {userData.signupInfo?.firstName}
            </div>
            <div className="desc">Welcome onboard!</div>
            <div className="fieldContainer">
              <InlineWidget url="https://calendly.com/kopidate/sydney" />
            </div>
          </div>
        </div>

        <Snackbar
          open={Boolean(snack)}
          onClose={() => setSnack('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div style={{ width: '100%', textAlign: 'center' }}>
            <div className={classes.snack}>{snack}</div>
          </div>
        </Snackbar>
      </Grid>
    </Grid>
  );
}
