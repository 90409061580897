/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import {
  Grid,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
// import InstagramEmbed from 'react-instagram-embed';
import images from '../../Configs/images';
import { BrownButton } from '../../Elements';
// import './swiper.css';

export default function Component({ classes, history }) {
  const [step, setStep] = useState(0);
  // console.log(classes, history);
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} sx={{ height: '100vh', bgcolor: '#1d2224', position: 'relative' }}>
          <div className={classes.root}>
            <div className="center">
              <div className="stepper">
                <div className={`step ${step === 0 && 'active'}`} />
                <div className={`step ${step === 1 && 'active'}`} />
                <div className={`step ${step === 2 && 'active'}`} />
              </div>
              <img src={images.Logo} alt="logo" width="80" style={{ marginTop: 24 }} />
            </div>

            <div>
              <SwipeableViews
                onChangeIndex={(index) => setStep(index)}
                disabled={step === 1}
                index={step}
                draggable
                enableMouseEvents
              >
                <div className="step1">
                  <div className="title">Ready to swipe your last swipe ever?</div>
                  <img src={images.Intro1} alt="intro" width="100" style={{ marginTop: 24 }} />
                  <img src={images.Intro2} alt="intro" width="100" />
                </div>

                <div className="step2">
                  <div className="content">
                    At Kopi Date, there is
                    <br />
                    <span className="highlighted">NO endless swiping</span>
                    {' '}
                    or
                    {' '}
                    <span className="highlighted">texting</span>
                    .
                  </div>
                  <div className="content" style={{ marginTop: 24 }}>
                    Enjoy conversation-centred dates
                    with our
                    {' '}
                    <span className="highlighted">curated community</span>
                    !
                  </div>
                </div>

                <div className="step3">
                  <div className="content">
                    The most
                    {' '}
                    <span className="highlighted">human-centred</span>
                    {' '}
                    social experience
                  </div>
                  {/* <InstagramEmbed
                    url="https://www.instagram.com/reel/CZ9VCZwlwRF"
                    clientAccessToken="2511761349056414|0b58fcbbf5c572a42838e1175000cfd3"
                    maxWidth={320}
                    hideCaption={false}
                    containerTagName="div"
                    protocol=""
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                  /> */}
                  <video width="320" height="240" controls style={{ marginTop: 24 }}>
                    <source src="https://firebasestorage.googleapis.com/v0/b/kopi-date-dev.appspot.com/o/video%2Fcharlottewang%20(1).mp4?alt=media&token=a04b77a0-3c9b-4d6a-9ace-f816707ff692" type="video/mp4" />
                  </video>
                </div>

              </SwipeableViews>

              <div className="floating-act" hidden={step !== 1}>
                <BrownButton text="CONTINUE" fullwidth styles={{ padding: '8px 0', fontWeight: '700' }} onClick={() => setStep(2)} />
              </div>
              <div className="floating-act" hidden={step !== 2}>
                <BrownButton text="I'M READY!" fullwidth styles={{ padding: '8px 0', fontWeight: '700', backgroundColor: '#41b586' }} onClick={() => history.replace('/login')} />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
