/* eslint-disable max-len */
import { React, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { ChevronLeftRounded } from '@mui/icons-material';
import './style.css';

export default function FAQ(props) {
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [selected3, setSelected3] = useState(null);
  const [selected4, setSelected4] = useState(null);
  const [selected5, setSelected5] = useState(null);
  const [selected6, setSelected6] = useState(null);
  const { history } = props;

  // const NewlineText = (props) => {
  //   const text = props.text;
  //   const newText = text.split('\n').map((str) => <p>{str}</p>);

  //   return newText;
  // };

  const toggleAccordion1 = (i) => {
    if (selected1 === i) {
      setSelected1(null);
      return;
    }
    setSelected1(i);
  };

  const toggleAccordion2 = (i) => {
    if (selected2 === i) {
      setSelected2(null);
      return;
    }
    setSelected2(i);
  };

  const toggleAccordion3 = (i) => {
    if (selected3 === i) {
      setSelected3(null);
      return;
    }
    setSelected3(i);
  };

  const toggleAccordion4 = (i) => {
    if (selected4 === i) {
      setSelected4(null);
      return;
    }
    setSelected4(i);
  };

  const toggleAccordion5 = (i) => {
    if (selected5 === i) {
      setSelected5(null);
      return;
    }
    setSelected5(i);
  };

  const toggleAccordion6 = (i) => {
    if (selected6 === i) {
      setSelected6(null);
      return;
    }
    setSelected6(i);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4} md={6} sm={8} xs={12}>
        <div className="root">
          <IconButton
            className="back"
            onClick={() => history.replace('/settings')}
          >
            <ChevronLeftRounded />
          </IconButton>
          <div className="wrapper">
            <div className="how-it-works-section">
              <div className="faq-title">Frequently Asked Questions</div>
              <div className="faq-section-title">HOW IT WORKS</div>
              <div className="accordion">
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion1(0)}>
                  <div className="question-title">
                    <h3>Why does Kopi Date exist? What is your mission?</h3>
                    <span className="symbol">
                      {selected1 === 0 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected1 === 0 ? 'answer show' : 'answer'}>
                    Kopi Date was founded on the idea of change. Our idea is to
                    humanise connections in an overly digitised world. We want
                    to create the most authentic offline dating experience for
                    singles.
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion1(1)}>
                  <div className="question-title">
                    <h3>So, how does Kopi Date work?</h3>
                    <span className="symbol">
                      {selected1 === 1 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected1 === 1 ? 'answer show' : 'answer'}>
                    It’s simple!
                    <p />
                    1. Sign up to be part of the Kopi Date Community.
                    <p />
                    2. Once you’re in, unlock the curation form by adding your
                    card.
                    <p />
                    3. Celebrate your individuality through a fun onboarding
                    call and receive good news about your Kopi Date from our
                    Kopi Artisans within 1-8 weeks.
                    <p />
                    4. Head down for your first anonymous dating experience at
                    our partnered concept spaces and share your adventures on
                    your socials!
                    <p />
                    5. Conclude your date with us through a quick review as the
                    Kopi Date team curates your next (even better) adventure! ☕
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion1(2)}>
                  <div className="question-title">
                    <h3>Is there an age limit?</h3>
                    <span className="symbol">
                      {selected1 === 2 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected1 === 2 ? 'answer show' : 'answer'}>
                    We encourage singles of all ages to sign up! However, as
                    we’d like all of our members to have quality experiences, we
                    would only enrol members who will have the best experience
                    at Kopi Date!
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion1(3)}>
                  <div className="question-title">
                    <h3>Do I get to talk to the person before we meet?</h3>
                    <span className="symbol">
                      {selected1 === 3 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected1 === 3 ? 'answer show' : 'answer'}>
                    Nope, it’s an anonymous date. Hold onto your excitement for
                    the date itself! You will get more than enough time to talk
                    to your date then. 😉
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion1(4)}>
                  <div className="question-title">
                    <h3> Where will the location of the meet-up be at?</h3>
                    <span className="symbol">
                      {selected1 === 4 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected1 === 4 ? 'answer show' : 'answer'}>
                    Your date location is a surprise and will be revealed 2-3
                    days before your date through email. Kopi Dates are held at
                    our partner venues, each with their own unique concept.
                    These locations have been carefully curated to provide the
                    best first date experiences.
                  </div>
                </div>
              </div>
            </div>

            <div className="the-curation-section">
              <div className="faq-section-title">THE CURATION</div>
              <div className="accordion">
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion2(0)}>
                  <div className="question-title">
                    <h3>
                      How do you go about selecting and curating the dates?
                    </h3>
                    <span className="symbol">
                      {selected2 === 0 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected2 === 0 ? 'answer show' : 'answer'}>
                    Our goal is to create the optimal environment for members to
                    connect through authentic conversation. With that in mind,
                    we curate dates based on conversational potential,
                    personality and background.
                    <p />
                    <a href="https://www.kopidate.com.au/handbook">
                      Our Philosophy.
                    </a>
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion2(1)}>
                  <div className="question-title">
                    <h3>Why isn&apos;t there a space for my preferences?</h3>
                    <span className="symbol">
                      {selected2 === 1 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected2 === 1 ? 'answer show' : 'answer'}>
                    Kopi Date encourages members to approach each date with an
                    open mind. After all, connections often develop in places we
                    don’t expect. However, please reach out to us if you have
                    any absolute limits!
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion2(2)}>
                  <div className="question-title">
                    <h3>Is there a preference for religion?</h3>
                    <span className="symbol">
                      {selected2 === 2 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected2 === 2 ? 'answer show' : 'answer'}>
                    This is unavailable at the moment but it is in the pipelines
                    and Kopi Artisans will see what we can do to assist here.
                  </div>
                </div>
              </div>
            </div>

            <div className="pricing-section">
              <div className="faq-section-title">THE PRICING</div>
              <div className="accordion">
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion3(0)}>
                  <div className="question-title">
                    <h3>
                      What does the membership from $36 (Ladies) | $45
                      (Gentlemen) include?
                    </h3>
                    <span className="symbol">
                      {selected3 === 0 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected3 === 0 ? 'answer show' : 'answer'}>
                    Our goal is to create the optimal environment for members to
                    It covers one Kopi Date Experience a month (curation and
                    concierge services, the date venue booking, a complimentary
                    beverage during the date, and the Kopi Date Experience Kit).
                    You will just be responsible for turning up with your
                    authentic self!
                    <p />
                    Memberships are on a 12-credits basis (30% off) | 6-credits
                    (20% off) basis with exciting perks (Surprise activity
                    dates, top priority curation, active personalized learning
                    for improved curation, exclusive monthly personal growth
                    content and white glove service).
                    <p />
                    3-credits are
                    available without above perks.
                    <p />
                    Membership will renew
                    when the cycle has ended.
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion3(1)}>
                  <div className="question-title">
                    <h3>What happens if there’s no scheduled date so far?</h3>
                    <span className="symbol">
                      {selected3 === 1 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected3 === 1 ? 'answer show' : 'answer'}>
                    Every Kopi Date Experience is thoughtfully-curated; hang in
                    there for a week or so as we might just be finalising your
                    date. Email us at
                    <a href="mailto: hello@kopidate.com">hello@kopidate.com</a>
                    and we’ll get you sorted as soon as possible.
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion3(2)}>
                  <div className="question-title">
                    <h3>
                      Will I get a refund if I’m unable to attend the date after
                      curation?
                    </h3>
                    <span className="symbol">
                      {selected3 === 2 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected3 === 2 ? 'answer show' : 'answer'}>
                    Unfortunately, your payment will be forfeited as operational
                    costs has been incurred in planning for the date. Please
                    inform the team in advance if you are no longer eligible for
                    upcoming Kopi Dates. No shows will result in a $50 penalty
                    to ensure a positive experience for all members.
                  </div>
                </div>
                <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion3(3)}>
                  <div className="question-title">
                    <h3>Help! I have questions regarding my membership.</h3>
                    <span className="symbol">
                      {selected3 === 3 ? '-' : '+'}
                    </span>
                  </div>
                  <div className={selected3 === 3 ? 'answer show' : 'answer'}>
                    Not to worry, simply drop us an email at
                    <a href="mailto: hello@kopidate.com">hello@kopidate.com</a>
                    and the team will get to you shortly.
                  </div>
                </div>
              </div>

              <div className="date-day-section">
                <div className="faq-section-title">DATE-DAY</div>
                <div className="accordion">
                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion4(0)}>
                    <div className="question-title">
                      <h3>How do I prepare myself for date-day?</h3>
                      <span className="symbol">
                        {selected4 === 0 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected4 === 0 ? 'answer show' : 'answer'}>
                      The most important things to keep in mind on date-day are
                      our
                      <br />
                      <a href="https://www.kopidate.com.au/handbook">
                        Kopi Date Values
                      </a>
                      : Zero Expectations, Open-Mindedness, and Mutual
                      Authenticity. Besides that, show up presentable and ready
                      to connect with a stranger!
                    </div>
                  </div>

                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion4(1)}>
                    <div className="question-title">
                      <h3>
                        What happens if I can’t turn up for the date due to
                        emergency reasons?
                      </h3>
                      <span className="symbol">
                        {selected4 === 1 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected4 === 1 ? 'answer show' : 'answer'}>
                      If you noticed that you’ll be unable to attend the date
                      prior to the date due to emergency reasons, please let us
                      know ASAP via email
                      <a href="mailto: hello@kopidate.com">
                        hello@kopidate.com
                      </a>
                      or contact us +65 96685098 on WhatsApp!
                      <p />
                      Note: For
                      last minute (
                      {'< 48'}
                      hours) rescheduling/ cancellations,
                      1 additional date credit will be incurred (billed to your
                      account), as all arrangements have been finalized and
                      partners have been confirmed. We seek your understanding
                      as we create the best experience for our community
                      members.
                    </div>
                  </div>

                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion4(2)}>
                    <div className="question-title">
                      <h3>
                        Anything else, I need to take note of before I take off?
                      </h3>
                      <span className="symbol">
                        {selected4 === 2 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected4 === 2 ? 'answer show' : 'answer'}>
                      Your safety is of utmost priority at Kopi Date. If you
                      feel unsafe at any point in the date, please inform us
                      directly through Whatsapp so that we can guide you
                      accordingly. For emergency cases, please dial 999 and
                      inform the relevant authorities.
                    </div>
                  </div>
                </div>
              </div>
              <div className="reschedule-policy-section">
                <div className="faq-section-title">RESCHEDULE-POLICY</div>
                <div className="accordion">
                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion5(0)}>
                    <div className="question-title">
                      <h3>
                        What happens if I’ll need to reschedule or cancel my
                        date?
                      </h3>
                      <span className="symbol">
                        {selected5 === 0 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected5 === 0 ? 'answer show' : 'answer'}>
                      Re-scheduling of dates can be done on our portal through a
                      rescheduling ticket 🎟️ (marginal cost). Do help to update
                      your availability to avoid it 😉 Seek your understanding
                      as we aim to create a fair and accountable experience to
                      the members in our Kopi Date community, ensuring that
                      overall, everyone has an enjoyable time with us!
                      <p />
                      Do
                      see the following questions for more details.
                      <p />
                      If you
                      still have any enquiries, do not hesitate to reach out to
                      us at
                      <a href="mailto: hello@kopidate.com">
                        hello@kopidate.com
                      </a>
                      .
                    </div>
                  </div>
                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion5(1)}>
                    <div className="question-title">
                      <h3>
                        I have other commitments (eg. unavailable on scheduled
                        day, emergency cases before confirmation) 3 days before
                        the date and need to reschedule.
                      </h3>
                      <span className="symbol">
                        {selected5 === 1 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected5 === 1 ? 'answer show' : 'answer'}>
                      We have taken into your account your indicated
                      availability on both you and your date’s profile. As such,
                      we seek your understanding to adhere to the proposed
                      timings as we’ll have to be accountable to both parties
                      and respect their time.
                      <p />
                      Do still reach out to us so
                      that we are aware of the next steps to take, and can
                      facilitate re-scheduling. We regret to inform you the
                      charges for that particular month’s curation might (on a
                      case-by-case basis) be forfeited and new charges will be
                      incurred on your rescheduled date, unless stated
                      otherwise.
                    </div>
                  </div>
                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion5(2)}>
                    <div className="question-title">
                      <h3>
                        There has been unforeseen circumstances (eg. last minute
                        cancellation upon confirmation).
                      </h3>
                      <span className="symbol">
                        {selected5 === 2 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected5 === 2 ? 'answer show' : 'answer'}>
                      Do inform us at least 3 days in advance if you’re unable
                      to turn up for your date so that we can facilitate the
                      rescheduling as well as to let your date know about the
                      change in plans!
                      <p />
                      We regret to inform you that once
                      you pass the 3-day mark, the charges for that particular
                      month’s curation will be forfeited as full operation costs
                      has been incurred and an addition credit will be billed to
                      you for rescheduling/ cancellation - if you are unable to
                      make it, please let us know so we can inform your date
                      that it has been cancelled.
                    </div>
                  </div>
                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion5(3)}>
                    <div className="question-title">
                      <h3>
                        I am currently seeing somebody else (after curation has
                        been made).
                      </h3>
                      <span className="symbol">
                        {selected5 === 3 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected5 === 3 ? 'answer show' : 'answer'}>
                      No worries, we understand your situation! Though, we’ll
                      still have to be accountable to your date on a sudden
                      change of plans. As such, your date credit will not be
                      refunded. Do also drop an email to:
                      <a href="mailto: hello@kopidate.com">
                        hello@kopidate.com
                      </a>
                      so that we can assist you in the pausing of your
                      subscription plan, and let us know whenever you will like
                      to proceed again (-:
                    </div>
                  </div>
                </div>
              </div>
              <div className="press-partnerships-section">
                <div className="faq-section-title">PRESS & PARTNERSHIPS</div>
                <div className="accordion">
                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion6(0)}>
                    <div className="question-title">
                      <h3>How can I get in touch with your press team?</h3>
                      <span className="symbol">
                        {selected6 === 0 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected6 === 0 ? 'answer show' : 'answer'}>
                      Email us at
                      <a href="mailto: press@kopidate.com">
                        press@kopidate.com
                      </a>
                      .
                    </div>
                  </div>

                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion6(1)}>
                    <div className="question-title">
                      <h3>
                        We love Kopi Date. How can we work on a partnership
                        together?
                      </h3>
                      <span className="symbol">
                        {selected6 === 1 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected6 === 1 ? 'answer show' : 'answer'}>
                      Aw, thanks! We&apos;re always innovating on the creating the
                      best experience for our community. Shoot us an email at
                      <a href="mailto: partnership@kopidate.com">
                        partnership@kopidate.com
                      </a>
                      —this could be the start of something magical.
                    </div>
                  </div>

                  <div className="item" role="button" onKeyDown={null} tabIndex={0} onClick={() => toggleAccordion6(2)}>
                    <div className="question-title">
                      <h3>How can I become a brand ambassador?</h3>
                      <span className="symbol">
                        {selected6 === 2 ? '-' : '+'}
                      </span>
                    </div>
                    <div className={selected6 === 2 ? 'answer show' : 'answer'}>
                      We don’t have brand ambassadors at this time, but we love
                      your enthusiasm! Please tag @kopi.date in any photos you
                      share on social media. We&apos;ll keep an eye on our social
                      channels and tag you if we re-post!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

// const data = [
//   {
//     question: 'Why does Kopi Date exist? What is your mission?',
//     answer:
//       'Kopi Date was founded on the idea of change.
//   },
//   {
//     question: 'So, how does Kopi Date work?',
//     answer: `
//       It’s simple! \n
//       1. Sign up to be part of the Kopi Date Community. \n
//       2. Once you’re in, unlock the curation form by adding your card. \n
//       3. Celebrate your individuality through a fun onboarding call and receive good news about your Kopi Date from our Kopi Artisans within 1-8 weeks. \n
//       4. Head down for your first anonymous dating experience at our partnered concept spaces and share your adventures on your socials! \n
//       5. Conclude your date with us through a quick review as the Kopi Date team curates your next (even better) adventure! ☕
//     `,
//   },
//   {
//     question: 'Is there an age limit?',
//     answer:
//       'We encourage singles of all ages to sign up! However, as we’d like all of our members to have quality experiences, we would only enrol members who will have the best experience at Kopi Date!',
//   },
//   {
//     question: 'Do I get to talk to the person before we meet?',
//     answer:
//       'Nope, it’s an anonymous date. Hold onto your excitement for the date itself! You will get more than enough time to talk to your date then. 😉',
//   },
//   {
//     question: 'Where will the location of the meet-up be at?',
//     answer:
//       'Your date location is a surprise and will be revealed 2-3 days before your date through email. Kopi Dates are held at our partner venues, each with their own unique concept. These locations have been carefully curated to provide the best first date experiences.',
//   },
// ];
