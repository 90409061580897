/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import {
  Autocomplete,
  Button, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField,
} from '@mui/material';
import { PauseRounded } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { BaseModal, BottomNav, ConfirmationModal } from '../../Elements';
import { auth, firestore } from '../../Configs/firebase';
import images from '../../Configs/images';
import { getUserData, setUserData } from '../../utils/storage';
import { addDays, amplytics } from '../../utils/helper';
import { brown } from '../../Configs/colors';

export default function ExtendPause({ classes }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - ExtendPause';
    amplytics('ExtendPause View');
  }, []);

  const radioStyle = {
    '&.Mui-checked': {
      color: '#9e7666',
    },
  };

  const userData = getUserData();
  const isPaused = userData.isPaused || false;

  const [loading, setLoading] = useState(false);

  const [popUpPause, setOpenPopPause] = useState(false);
  const [pauseDays, setPauseDays] = useState(0);
  const [pauseReason, setPauseReason] = useState('');

  const [daysError, setDaysError] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  const handleTogglePauseCuration = async () => {
    if (!isPaused && !pauseDays) {
      setDaysError(true);
      return;
    }
    if (!isPaused && !pauseReason) {
      setReasonError(true);
      return;
    }

    setLoading(true);
    setOpenPopPause(false);
    const pausingLogs = userData.pausingLogs || [];
    const now = new Date();

    const newLog = {
      action: isPaused ? 'Unpaused' : 'Paused',
      actionTime: now,
      description: isPaused ? "Resumed by member" : "Paused by member"
    };
    if (pauseReason) newLog.pauseReason = pauseReason;

    const body = {
      pausingLogs: {
        ...pausingLogs,
        [+now]: newLog,
      },
      isPaused: !isPaused,
    };
    if (pauseDays) {
      const pauseUntil = addDays(now, Number(pauseDays));
      body.pauseUntil = pauseUntil;
    } else { body.pauseUntil = null; }
    body.pauseReason = pauseReason || null;

    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser.uid}`);

    await updateDoc(docRef, body)
      .then(async () => {
        amplytics('Updated PauseCuration');
        await setUserData(auth.currentUser);
      }).catch((err) => {
        alert(err.message);
        console.log(err);
      });
    setPauseDays('');
    setPauseReason('');
    setLoading(false);
  };

  const extendPauseCuration = async () => {
    if (!pauseDays) {
      setDaysError(true);
      return;
    }
    if (!pauseReason) {
      setReasonError(true);
      return;
    }

    setLoading(true);
    setOpenPopPause(false);
    const pausingLogs = userData.pausingLogs || [];
    const now = new Date();

    const newLog = {
      action: 'Paused',
      actionTime: now,
      description: isPaused ? "Resumed by member" : "Paused by member"
    };
    if (pauseReason) newLog.pauseReason = pauseReason;

    const body = {
      pausingLogs: {
        ...pausingLogs,
        [+now]: newLog,
      },
      isPaused: true,
    };

    const currentPauseUntil = userData.pauseUntil
      ? new Date(userData.pauseUntil?.seconds * 1000) : now;
    const pauseUntil = addDays(currentPauseUntil, Number(pauseDays));
    body.pauseUntil = pauseUntil;
    body.pauseReason = pauseReason || null;

    const docRef = doc(firestore, `/userPrivateData/${auth.currentUser.uid}`);

    await updateDoc(docRef, body)
      .then(async () => {
        await setUserData(auth.currentUser);
      }).catch((err) => {
        alert(err.message);
        console.log(err);
      });
    setPauseDays('');
    setPauseReason('');
    setLoading(false);
  };

  const handleTogglePopUp = () => {
    setOpenPopPause(!popUpPause);
  };

  const pauseReasons = [
    'Need a social break',
    'Need some time to know past date better',
    'Busy work period',
    'Traveling abroad',
    'Others (please specify): '];

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          {loading
            && (
            <div className={classes.loading}>
              <img alt="loading" src={images.Loading} width="100" height="100" />
            </div>
            )}
          <div className={classes.root}>
            <Container>
              <div className="head">Extend Pause Curations</div>
              <div style={{ marginTop: 20 }}>
                {isPaused
                  ? (
                    <div>

                      {userData?.pauseUntil
                    && (
                    <div style={{ marginBottom: 12 }}>
                      You are now paused until
                      {new Date(userData.pauseUntil.seconds * 1000).toDateString()}
                    </div>
                    )}
                      <FormControl component="fieldset" error={daysError}>
                        <FormLabel component="legend">How many days & why do you want to extend your curation pause?</FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          value={pauseDays}
                          name="radio-buttons-group"
                          onChange={(e) => setPauseDays(e.target.value)}
                          sx={{ mt: 2 }}
                        >
                          <FormControlLabel value={30} control={<Radio size="small" sx={radioStyle} />} label="30 days" />
                          <FormControlLabel value={45} control={<Radio size="small" sx={radioStyle} />} label="45 days" />
                          <FormControlLabel value={60} control={<Radio size="small" sx={radioStyle} />} label="60 days" />
                        </RadioGroup>
                      </FormControl>
                      <div style={{ fontSize: 16, marginTop: 14, marginBottom: 8 }}>
                        Why you&apos;d like to extend your pause?
                      </div>
                      <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={pauseReasons}
                        error={reasonError}
                        onChange={(e, value) => setPauseReason(value)}
                        renderInput={(params) => <TextField {...params} error={reasonError} fullWidth size="small" sx={{ mb: 1 }} placeholder="Select Reason" />}
                      />
                      <div style={{ marginTop: 6, fontSize: 14, textAlign: 'center' }}>
                        Need to pause for longer? Write in to us at
                        {' '}
                        <a href="mailto:hello@kopidate.com?subject=I want to pause my account for more than 60 days" target="_blank" style={{ fontSize: 14, textDecoration: 'none' }} rel="noreferrer">hello@kopidate.com</a>
                      </div>
                      <Button
                        style={{
                          color: '#fff', backgroundColor: brown, textTransform: 'none', marginTop: 20,
                        }}
                        fullWidth
                        onClick={extendPauseCuration}
                      >
                        APPLY
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div>Currently you are not paused</div>
                      <div role="button" onKeyDown={null} tabIndex={0} className="action" onClick={handleTogglePopUp}>
                        <div className="manualbg-action-name">
                          <PauseRounded htmlColor="#9e8469" style={{ fontSize: 12 }} />
                        </div>
                        <div className="action-name">Pause curations</div>
                      </div>
                    </>
                  )}
              </div>
            </Container>
          </div>
        </Grid>
      </Grid>
      <BottomNav active={3} />

      <ConfirmationModal title="Resumption Account" open={popUpPause && isPaused} handleClose={handleTogglePopUp} handleAgree={handleTogglePauseCuration} />
      <BaseModal title="Pause Curation" open={popUpPause && !isPaused} handleClose={handleTogglePopUp} handleSubmit={handleTogglePauseCuration}>
        <FormControl component="fieldset" error={daysError}>
          <FormLabel component="legend">How many days & why you&apos;d like to pause your curations?</FormLabel>
          <RadioGroup
            aria-label="gender"
            value={pauseDays}
            name="radio-buttons-group"
            onChange={(e) => setPauseDays(e.target.value)}
            sx={{ mt: 2 }}
          >
            <FormControlLabel value={14} control={<Radio size="small" sx={radioStyle} />} label="30 days" />
            <FormControlLabel value={21} control={<Radio size="small" sx={radioStyle} />} label="45 days" />
            {/* <FormControlLabel
            value={60} control={<Radio size="small" sx={radioStyle} />} label="60 days" /> */}
          </RadioGroup>
        </FormControl>
        <div style={{ fontSize: 16, marginTop: 14, marginBottom: 8 }}>
          Why you&apos;d like to pause?
        </div>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          options={pauseReasons}
          error={reasonError}
          onChange={(e, value) => setPauseReason(value)}
          renderInput={(params) => <TextField {...params} error={reasonError} fullWidth size="small" sx={{ mb: 1 }} placeholder="Select Reason" />}
        />
        <div style={{ marginTop: 6, fontSize: 14, textAlign: 'center' }}>
          Need to pause for longer? Write in to us at
          {' '}
          <a href="mailto:hello@kopidate.com?subject=I want to pause my account for more than 60 days" target="_blank" style={{ fontSize: 14, textDecoration: 'none' }} rel="noreferrer">hello@kopidate.com</a>
        </div>
      </BaseModal>
    </>
  );
}
