import React from 'react'
import { withStyles } from '@mui/styles';
import styles from "./styles"
import images from '../../Configs/images';
import { useEffect } from 'react';
import {isMobile} from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { getUserId } from '../../utils/storage';

const Quiz = (props) => {
    const { search, pathname } = useLocation()
    const userId = getUserId()

    useEffect(() => {
        if (isMobile) {
            let redirect_url = ""

            if (pathname == "/conclude") {
                redirect_url = process.env.REACT_APP_DEEPLINK + `/conclude`
            } else if (pathname == "/availability") {
                redirect_url = process.env.REACT_APP_DEEPLINK + `/availability`
            } else if (pathname == "/chat") {
                redirect_url = process.env.REACT_APP_DEEPLINK + `/mainapp/chat`
            } else if (pathname == "/settings") {
                redirect_url = process.env.REACT_APP_DEEPLINK + `/mainapp/settings`
            } else if (pathname == "/knowing-the-real-you") {
                redirect_url = process.env.REACT_APP_DEEPLINK + `/gtky`
            } else if (pathname == "/quiz") {
                const searchParams = new URLSearchParams(search)
                const form = searchParams.get("form")
                redirect_url = process.env.REACT_APP_DEEPLINK + `/quiz/${encodeURIComponent(form)}`
            }

            window.location.assign(redirect_url)
        } else {
            // redirect back to preferred url
            if (!userId) {
                window.location.href =  "/"
            }
        }
    }, [])

    const { classes } = props;
    return (
        <div className={classes.loading}>
            <div className="loadingText">Please wait...</div>
            <img alt="loading" src={images.Loading} width="100" height="100" />
        </div>
    )
}

export default withStyles(styles)(Quiz);