/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import {
  Button, Container, Grid, TextField, ClickAwayListener,
} from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import { SentimentVerySatisfiedOutlined } from '@mui/icons-material';
import {
  onValue, push, ref, set,
} from '@firebase/database';
import { doc, getDoc } from '@firebase/firestore';
import { BottomNav } from '../../Elements';
import images from '../../Configs/images';
import styles from './chat.module.css';
import sendEmail from '../../sendEmail';
import { auth, db, firestore } from '../../Configs/firebase';
import { admin } from '../../constans/adminList';
import { brown } from '../../Configs/colors';
import { getNextMatch, getTodaysMatch } from '../../utils/storage';
import sendSMS from '../../sendSMS';
import { amplytics, datesAreOnSameDay } from '../../utils/helper';

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        displayName: null,
        email_id: null,
        uid: null,
      },
      // matchData: {},
      otherUserDisplayName: null,
      otherUserEmailId: null,
      otherUserUid: null,
      chats: [],
      content: '',
      error: null,
      matchId: null,
      validUser: false,
      showEmojiMenu: false,
      loading: true,
      reschedule: false,
      walkAndTalk: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    document.title = 'Kopi Date Experience - Chat';
    amplytics('Chat View');
    window.scrollTo(0, 1);

    let { matchId } = this.props.match.params;
    let { userHash } = this.props.match.params;

    if (!matchId || !userHash) {
      let matchData = await getTodaysMatch();
      const nextMatch = await getNextMatch();
      if ((nextMatch.askForReschedule && !matchData.askForReschedule) || !matchData) {
        matchData = nextMatch;
      }

      if (!matchData) {
        this.props.history.replace('/kit');
        return;
      }

      // this.setState({ matchData });

      console.log('matchId', matchData.matchId);
      matchId = matchData.matchId;
      userHash = auth.currentUser.uid;

      const rescheduleChat = matchData.askForReschedule;
      const bothConfirmed = matchData.femaleConfirmation && matchData.maleConfirmation;
      // this feature only for reschedule chat and dateDayChat(if both confirmed)
      if (!rescheduleChat && !bothConfirmed) {
        this.props.history.replace('/kit');
        return;
      }

      if ([238858, 160049, 188778, 161048].includes(matchData.postalCode)) {
        this.setState({ walkAndTalk: true });
      }
      this.renderData(matchId, userHash, rescheduleChat, matchData);
    } else {
      const matchSnap = await getDoc(doc(firestore, `matches/${matchId}`));
      let matchData;
      if (matchSnap.exists()) {
        matchData = matchSnap.data();
      }

      if (!matchData) {
        this.props.history.replace('/kit');
        return;
      }

      // this.setState({ matchData });

      console.log('matchId', matchData.matchId);
      const now = new Date();
      const matchDate = new Date(matchData.date.seconds * 1000);

      const rescheduleChat = matchData.askForReschedule;

      const bothConfirmed = matchData.femaleConfirmation && matchData.maleConfirmation;
      const todaysMatch = datesAreOnSameDay(matchDate, now);
      const dateDayChat = bothConfirmed && todaysMatch;

      const itsAdmin = admin.includes(auth.currentUser?.email?.toLowerCase());
      // this feature only for reschedule chat and dateDayChat(if both confirmed) or for admin
      if (
        !rescheduleChat
        && !dateDayChat
        && !itsAdmin) {
        alert('You don\'t have any reschedule chat or date-day chat. Please report to admin if you get this as system error');
        this.props.history.replace('/kit');
        return;
      }

      if ([238858, 160049, 188778, 161048].includes(matchData.postalCode)) {
        this.setState({ walkAndTalk: true });
      }
      this.renderData(matchId, userHash, rescheduleChat, matchData);
    }

    this.setState({ loading: false });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { reschedule } = this.state;
    this.setState({ error: null });
    if (this.state.content !== '') {
      try {
        let isFirstMsg;
        let last_msg;
        let cur_timestamp;
        let time_elapsed_seconds;
        let time_elapsed_minutes;

        console.log(this.state.chats);
        if (Array.isArray(this.state.chats) && this.state.chats.length) {
          console.log('last msg exists');
          isFirstMsg = false;
          last_msg = this.state.chats[this.state.chats.length - 1];
          console.log(last_msg);
          console.log(last_msg.timestamp);
          cur_timestamp = new Date().getTime();
          console.log(cur_timestamp);
          time_elapsed_seconds = Math.floor((cur_timestamp - last_msg.timestamp) / 1000);
          time_elapsed_minutes = Math.floor(time_elapsed_seconds / 60);
          console.log(`time elapsed: ${time_elapsed_minutes}`);
        } else { isFirstMsg = true; }

        const dateChatRef = ref(db, `${reschedule ? 'reschedule-chat' : 'date-chat'}/${this.state.matchId}/history`);
        await push(dateChatRef, {
          author: this.state.user,
          content: this.state.content,
          timestamp: Date.now(),
          uid: this.state.user.uid,
        });

        // const usersRef = ref(db, `${reschedule ? 'reschedule-chat'
        // : 'date-chat'}/${this.state.matchId}/users`);
        // const maleInfo = this.state.matchData.signupInfoMale || {};
        // const femaleInfo = this.state.matchData.signupInfoFemale || {};
        // await set(usersRef, {
        //   [maleInfo.uid]: {
        //     displayName: this.state.matchData.maleAlias,
        //     email: maleInfo.email,
        //   },
        //   [femaleInfo.uid]: {
        //     displayName: this.state.matchData.femaleAlias,
        //     email: femaleInfo.email,
        //   },
        // });

        console.log(this.state.content);
        console.log(this.state.matchId);
        console.log(this.state.otherUserEmailId);
        console.log(this.state.otherUserUid);

        // TODO: send email notification

        const emailTarget = this.state.otherUserEmailId;
        const targetName = this.state.otherUserDisplayName;
        const targetUid = this.state.otherUserUid;
        const partner_Name = this.state.user.displayName;
        const message = this.state.content;
        const match_Id = this.state.matchId;

        // TODO: send email notification

        if (isFirstMsg) {
          console.log('first msg being sent');
          sendEmail(
            emailTarget,
            targetUid,
            targetName,
            partner_Name,
            message,
            match_Id,
          );

          const otherUserQuery = doc(firestore, `user/${targetUid}`);
          const otherUserSnap = await getDoc(otherUserQuery);
          const otherUserData = otherUserSnap.data();
          console.log('otherUserData', otherUserData);
          const otherUserPhone = otherUserData?.signupInfo?.contactNumber;
          console.log('otherUserPhone', otherUserPhone);

          sendSMS({
            message: `Hey ${targetName}, a chat has been initiated by your date. Kindly login below to reply! https://experience.kopidate.com.au/chat`,
            receiver: `+65${otherUserPhone}`,
          });
        } else if ((last_msg.author.uid !== this.state.user.uid) || (time_elapsed_minutes >= 10)) {
          console.log('last msg exists');
          sendEmail(
            emailTarget,
            targetUid,
            targetName,
            partner_Name,
            message,
            match_Id,
          );
        }

        this.setState({ content: '' });
        const plcholder = new URLSearchParams(this.props.location.search).get('placeholder');
        if (plcholder) { this.props.history.replace(this.props.location.pathname); }
      } catch (error) {
        this.setState({ error: error.message });
      }
    }
  }

  onEmojiClick = (e, emojiObject) => {
    this.setState({
      showEmojiMenu: false,
      content: this.state.content + emojiObject.emoji,
    });
  };

  handleToMail = () => {
    const body = 'Hey Kopi Artisan, I\'ve finalized with my date and would like to reschedule it to: (date), (time). Thank you!';
    window.open(`mailto:hello@kopidate.com?subject=Kopi Date Rescheduling&body=${body}`, '_blank');
  }

  scrollToBottom = () => {
    this.messagesEnd?.scrollIntoView({ behavior: 'smooth' });
  }

 renderData = async (matchId, userHash, reschedule, matchData) => {
   // console.log(auth.currentUser.uid)
   let noAuth = false;
   const configRef = ref(db, 'config/noAuthRequired');
   onValue(configRef, (snapshot) => {
     noAuth = snapshot.val();
   });
   // console.log('noAuth', noAuth)
   //  console.log('bacot', matchId, userHash);

   this.setState({ matchId, error: null });
   const plcholder = new URLSearchParams(this.props.location.search).get('placeholder');

   if (plcholder) {
     this.setState({ content: plcholder });
   }

   try {
     if (noAuth
      || admin.includes(auth.currentUser?.email?.toLowerCase())
      || auth.currentUser.uid === userHash) {
       this.populateChat(matchId, reschedule);

       //  Users data:start
       const itsMe = matchData.signupInfoFemale.uid === userHash;
       this.setState({
         validUser: true,
         user: {
           displayName: itsMe ? matchData.femaleAlias : matchData.maleAlias,
           email_id: itsMe ? matchData.signupInfoFemale.email : matchData.signupInfoMale.email,
           uid: userHash,
         },
         otherUserDisplayName: itsMe ? matchData.maleAlias : matchData.femaleAlias,
         otherUserEmailId: itsMe ? matchData.signupInfoMale.email
           : matchData.signupInfoFemale.email,
         otherUserUid: itsMe ? matchData.signupInfoMale.uid : matchData.signupInfoFemale.uid,
       });
       // Users data:end

       //  this.checkValidUser(matchId, userHash, reschedule, matchData);
       this.setState({ loading: false, reschedule });
     } else this.props.history.replace('/kit');
   } catch (error) {
     this.setState({ error: error.message, loading: false });
     alert(`${error.message} \nPlease report to admin! Your uid: ${userHash}`);
     this.props.history.replace('/');
   }
 }

  handleChange = (event) => {
    this.setState({
      content: event.target.value,
    });
  }

  onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  timeConverter = (UNIX_timestamp) => {
    const time = new Date(UNIX_timestamp);
    return (
      `${time.toLocaleDateString('en-US')} ${time.toLocaleTimeString('en-US')}`
    );
  }

  populateChat(matchId, reschedule) {
    const dateChatRef = ref(db, `${reschedule ? 'reschedule-chat' : 'date-chat'}/${matchId}/history`);
    onValue(dateChatRef, (snapshot) => {
      const chats = [];
      snapshot.forEach((item) => {
        chats.push(item.val());
      });
      this.setState({ chats });
    });
  }

  checkValidUser(matchId, userHash, reschedule) {
    const chatRef = ref(db, `${reschedule ? 'reschedule-chat' : 'date-chat'}/${matchId}/users`);
    onValue(chatRef, (snapshot) => {
      const validUsers = {};
      snapshot.forEach((item) => {
        validUsers[item.key] = item.val();
      });

      if (userHash in validUsers) {
        this.setState({
          validUser: true,
          user: {
            displayName: validUsers[userHash].displayName,
            email_id: validUsers[userHash].email,
            uid: userHash,
          },
          otherUserDisplayName:
            validUsers[
              Object.keys(validUsers).filter((uid) => uid !== userHash)[0]
            ].displayName,

          otherUserEmailId: validUsers[
            Object.keys(validUsers).filter((uid) => uid !== userHash)[0]
          ].email,

          otherUserUid:
            Object.keys(validUsers).filter((uid) => uid !== userHash)[0],
        });
      }
    });
  }

  showChat() {
    return (
      this.state.chats?.length !== 0
      && this.state.chats?.map((chat) => {
        if (chat.author?.uid === this.state.user?.uid) {
          // is me
          return (
            <li
              key={chat.timestamp}
              style={{
                alignSelf: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '80%',
                overflowWrap: 'break-word',
              }}
            >
              <div className={styles.myMessageDetails}>
                <span className={styles.messageTime}>
                  {this.timeConverter(chat.timestamp)}
                </span>
                &nbsp; &nbsp;
                <span className={styles.messageAuthor}>
                  {chat.author?.displayName}
                </span>
                <div className={styles.circleBlue} />
              </div>
              <div className={styles.myMessageContent}>{chat.content}</div>
            </li>
          );
        }
        return (
          <li
            key={chat.timestamp}
            style={{
              alignSelf: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              maxWidth: '80%',
              overflowWrap: 'break-word',
            }}
          >
            <div className={styles.otherMessageDetails}>
              <div className={styles.circleGreen} />
              <span className={styles.messageAuthor}>
                {chat.author?.displayName}
              </span>
                &nbsp; &nbsp;
              <span className={styles.messageTime}>
                {this.timeConverter(chat.timestamp)}
              </span>
            </div>
            <div className={styles.otherMessageContent}>{chat.content}</div>
          </li>
        );
      })
    );
  }

  render() {
    const {
      matchId,
      validUser, otherUserDisplayName, loading, reschedule, walkAndTalk,
    } = this.state;
    return (
      <>
        <Grid container justifyContent="center">
          <Grid item lg={4} md={6} sm={8} xs={12} style={{ position: 'relative', height: '100vh' }}>
            {loading
              ? (
                <div style={{
                  textAlign: 'center',
                  background: '#fef8f4',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '95vh',
                }}
                >
                  <img alt="loading" src={images.Loading} style={{ width: 100, height: 100 }} />
                </div>
              )
              : (
                <div style={{
                  height: '100vh',
                  background: '#eaeaea',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
                >
                  <div style={{ background: '#fef8ee' }}>
                    <Container>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '14px 0',
                        boxSizing: 'content-box',
                      }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img alt="kopi" src={images.Kopi} height="32" width="32" />
                          <div style={{ color: '#a78c58', marginLeft: 10 }}>
                            Chatting with
                            <strong>
                              {' '}
                              {otherUserDisplayName}
                            </strong>
                          </div>
                        </div>
                        <img alt="kopi date" src={images.KopiDate} width="40" height="24" style={{ marginRight: 10 }} />
                      </div>
                    </Container>
                  </div>
                  <div style={{
                    overflowY: 'auto',
                    boxSizing: 'content-box',
                    position: 'absolute',
                    top: 62,
                    bottom: 170,
                    right: 0,
                    left: 0,
                    paddingTop: 10,
                    overflowX: 'hidden',
                  }}
                  >
                    <Container>
                      {reschedule
                      && (
                      <div className={styles.firstSystemMessage}>
                        Note: Kindly use this chat to reschedule the date
                        <strong> within 2 weeks</strong>
                        {' '}
                        from the proposed date.
                        Rescheduling must be finalised in
                        {' '}
                        <strong>4 days time</strong>
                        .
                        <br />
                        <br />
                        To provide the best experience for everyone, kindly keep the chat
                        {' '}
                        <strong>anonymous</strong>
                        {' '}
                        by not revealing any personal information (i.e. name, contact details).
                        <div hidden={!walkAndTalk}>
                          <br />
                          You&apos;re going on a Walk and Talk date!
                          For best experience, kindly reschedule to 3:30pm onwards~
                        </div>
                      </div>
                      )}
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {this.showChat()}
                        <div ref={(el) => { this.messagesEnd = el; }} />
                      </div>
                    </Container>
                  </div>
                  <div style={{
                    position: 'absolute',
                    bottom: 64,
                    left: 0,
                    right: 0,
                  }}
                  >
                    <Container>
                      <form onSubmit={this.handleSubmit}>
                        {reschedule
                    && (
                    <Button
                      variant="outlined"
                      className={styles.confirmBtn}
                      onClick={this.handleToMail}
                      size="small"
                    >
                      Click here to finalise rescheduling
                    </Button>
                    )}
                        <TextField
                          fullWidth
                          variant="outlined"
                          multiline
                          onChange={this.handleChange}
                          value={this.state.content}
                          onKeyDown={(e) => this.onEnterPress(e)}
                          style={{ backgroundColor: '#fff', borderRadius: 8 }}
                          InputProps={{
                            endAdornment: (
                              <ClickAwayListener
                                onClickAway={() => this.setState({ showEmojiMenu: false })}
                              >
                                <div className={styles.sendAndEmojiContainer}>
                                  <div className={styles.emojiPicker}>
                                    {this.state.showEmojiMenu && (
                                    <EmojiPicker
                                      onEmojiClick={this.onEmojiClick}
                                    />
                                    )}
                                  </div>
                                  <div
                                    onClick={() => this.setState({
                                      showEmojiMenu: true,
                                    })}
                                    style={{ cursor: 'pointer', marginTop: 4 }}
                                  >
                                    <SentimentVerySatisfiedOutlined style={{ color: '#7ea4d7' }} />
                                  </div>
                                  <button type="submit" className={styles.send}>Send</button>

                                </div>
                              </ClickAwayListener>
                            ),
                          }}
                        />
                        {this.state.error ? (
                          <div className={styles.error}>{this.state.error}</div>
                        ) : null}

                      </form>
                    </Container>
                  </div>
                </div>
              )}
          </Grid>
        </Grid>
        <BottomNav active={1} />
      </>
    );
  }
}
