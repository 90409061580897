/* eslint-disable import/no-cycle */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
import {
  collection, doc, getDoc, getDocs, query as firestoreQuery, Timestamp, where, orderBy,
} from '@firebase/firestore';
import { getDownloadURL, ref as refStorage } from '@firebase/storage';
import { firestore, storage } from '../Configs/firebase';
import { datesAreOnSameDay } from './helper';

const USER_DATA = 'kopidate_experienceau_user_data';
const USER_DATA1 = 'kopidate_experienceau_user_data1';
const PROFILE_PICT = 'kopidate_experienceau_profile_picture';
const LAST_MATCH = 'kopidate_experienceau_last_match';
const TODAYS_MATCH = 'kopidate_experienceau_todays_match';
const GETTING_TO_KNOW_YOU = 'kopidate_experienceau_GETTING_TO_KNOW_YOU';
const REFERRAL_INFO = 'kopidate_experienceau_REFERRAL_INFO';
const NEXT_MATCH = 'kopidate_experienceau_next_match';
const TOTAL_MATCH_COUNT = 'kopidate_experienceau_total_match_count';
const EXPLORE_PEOPLE = 'kopidate_experienceau_explore_people';
const ENVIRONMENT = 'kopidate_experienceau_env';
const PAST_DATES = 'kopidate_experienceau_past_dates';
const PAST_CANCELLED_DATES = 'kopidate_experienceau_past_canceled_dates';
const LIKED_PEOPLE = 'kopidate_experienceau_liked_people';
const LOGIN_PLATFORM_TYPE = 'kopidate_experienceau_login_platform_type';
const U_ID = 'kopidate_experienceau_user_id';
export function clearStorage() {
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem(USER_DATA1);
  localStorage.removeItem(LAST_MATCH);
  localStorage.removeItem(TODAYS_MATCH);
  localStorage.removeItem(NEXT_MATCH);
  localStorage.removeItem(PROFILE_PICT);
  localStorage.removeItem(GETTING_TO_KNOW_YOU);
  localStorage.removeItem(REFERRAL_INFO);
  localStorage.removeItem(EXPLORE_PEOPLE);
  localStorage.removeItem(ENVIRONMENT);
  localStorage.removeItem(PAST_DATES);
  localStorage.removeItem(PAST_CANCELLED_DATES);
  localStorage.removeItem(LIKED_PEOPLE);
  localStorage.removeItem(LOGIN_PLATFORM_TYPE);
  localStorage.removeItem(U_ID);
  localStorage.removeItem(TOTAL_MATCH_COUNT)
}

export async function getamplitudeSetup() {
  const envData1 = doc(firestore, 'environment/settings');
  const envSnapData = await getDoc(envData1);
  let envSnapDatas;
  if (envSnapData.exists()) {
    envSnapDatas = envSnapData.data();
    return envSnapDatas.amplitudeKey;
  }
  return null;
}
export async function setUserData(itsYou) {
  if (itsYou) {
    localStorage.removeItem(LAST_MATCH);
    localStorage.removeItem(TODAYS_MATCH);
    localStorage.removeItem(NEXT_MATCH);

    let userData = itsYou;
    let userData1 = { convoRateAvg: '0' };
    let fn = '';
    if (itsYou.displayName) { fn = itsYou.displayName.split(' ')[0]; }
    const docRef = doc(firestore, `userPrivateData/${itsYou.uid}`);
    // const docRef1 = doc(firestore, 'test/test105');
    const docSnap = await getDoc(docRef);
    // const docSnap1 = await getDoc(docRef1);

    let conversationalScore = 0;
    if (docSnap.exists()) {
      const docData = docSnap.data();
      userData = { ...itsYou, ...docData };
      if (docData.onboardingInfo) {
        conversationalScore = docData.onboardingInfo.conversationalScore || 0;
        if ((typeof conversationalScore) === 'string') {
          if (conversationalScore === 'excellent') {
            conversationalScore = 4.75;
          } else if (conversationalScore === 'great') {
            conversationalScore = 4.25;
          } else if (conversationalScore === 'average') {
            conversationalScore = 3.75;
          } else if (conversationalScore === 'low') {
            conversationalScore = 3.25;
          } else {
            conversationalScore = 2.5;
          }
        }
      }
    } else {
      const docData = { signupInfo: { firstName: fn } };
      userData = { ...itsYou, ...docData };
      conversationalScore = 0;
    }
    // if (docSnap1.exists()) {
    //   const userDocData1 = docSnap1.data() || {};
    //   if (userDocData1[itsYou.uid]) {
    //     const myScore = userDocData1[itsYou.uid][0] || {};
    //     userData1 = { convoRateAvg: myScore.user1_feedback_score || 0 };
    //   }
    // }

    const userRef = doc(firestore, `user/${itsYou.uid}`);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const user = userSnap.data();
      setGettingToKnowYou(user?.gettingToKnowYouInfo);
      setReferralInfo(user?.referralInfo);

      const pictureURL = user?.signupInfo?.authEmail || itsYou.uid;
      const ppRef = refStorage(storage, `profile-picutre/${pictureURL}`);
      await getDownloadURL(ppRef)
        .then((url) => {
          setProfilePicture(url);
        })
        .catch((err) => {
          console.log(err);
        });

      if (user.signupInfo) {
        userData.signupInfo = { ...userData.signupInfo, ...user.signupInfo };
      }
    }

    // amplitude analytics : START
    // const envData1 = doc(firestore, 'environment/settings');
    // const envSnapData = await getDoc(envData1);
    // let envSnapDatas;
    // if (envSnapData.exists()) {
    //   envSnapDatas = envSnapData.data();
    // }
    // amplitude.getInstance().init(envSnapDatas.amplitudeKey, itsYou.uid);
    // amplitude.getInstance().setUserId(itsYou.uid);
    // amplitude.getInstance().setUserProperties({
    //   uid: itsYou.uid,
    //   gender: userData.signupInfo?.gender,
    //   age: getAge(new Date(userData.signupInfo?.age?.seconds * 1000)),
    //   enrolled: new Date(userData.enrollmentDate?.seconds * 1000).toString(),
    //   planType: userData.planType || 0,
    // });
    // amplitude analytics : END

    localStorage.setItem(USER_DATA, JSON.stringify(userData));
    localStorage.setItem(USER_DATA1, JSON.stringify(userData1));

    // Last Match
    const today = new Date();
    today.setHours(23, 59, 59, 0);
    const lastHourToday = Timestamp.fromDate(today);
    const matchRef = collection(firestore, 'matches');
    const userLikesRef = doc(firestore, `userLikes/${itsYou.uid}`);

    const uidByGender = userData?.signupInfo?.gender === 'Male' ? 'signupInfoMale.uid' : 'signupInfoFemale.uid';
    const q = firestoreQuery(matchRef, where(uidByGender, '==', itsYou.uid), where('date', '<=', lastHourToday), orderBy('date', 'asc'));
    const q2 = firestoreQuery(matchRef, where(uidByGender, '==', itsYou.uid), where('date', '>', lastHourToday), orderBy('date', 'asc'));
    const strq = `initial.${itsYou.uid}`;
    const matchSnapshot = await getDocs(q);
    const matchSnapshot2 = await getDocs(q2);

    const userLikesSnap = await getDoc(userLikesRef);
    let userLikes = [];
    console.log(strq, 'Hi hello');
    if (userLikesSnap.data()) {
      userLikes = userLikesSnap.data()?.recommend || [];
    }

    const past_dates = [];
    const past_cancelled_dates = [];

    let sum_score = 0;
    let num_feedback = 0;
    matchSnapshot?.forEach((match) => {
      const matchData = match.data();
      if (!matchData.dateCanceled || matchData.askForReschedule) {
        setLastMatch(matchData);
        const matchDay = new Date(matchData?.date?.seconds * 1000);
        // console.log(matchDay);
        if (datesAreOnSameDay(matchDay, new Date())) {
          setTodaysMatch(matchData);
        } else {
          if (userData?.signupInfo?.gender === 'Male') {
            if (matchData.femaleFeedback?.conservationRatings !== undefined
              && Number(matchData.femaleFeedback?.conservationRatings) > -1) {
              sum_score += Number(matchData.femaleFeedback?.conservationRatings);
              console.log(matchData.femaleFeedback?.conservationRatings);
              num_feedback += 1;
            }
          } else if (matchData.maleFeedback?.conservationRatings !== undefined
              && Number(matchData.maleFeedback?.conservationRatings) > -1) {
            sum_score += Number(matchData.maleFeedback?.conservationRatings);
            console.log(matchData.maleFeedback?.conservationRatings);
            num_feedback += 1;
          }
          past_dates.unshift(matchData);
        }
      } else {
        past_cancelled_dates.unshift(matchData);
      }

        setTotalMatchCount( getTotalMatchCount() + 1)
    });
    console.log(sum_score);
    console.log(num_feedback);
    console.log(conversationalScore);

    let convoRateAvg = 0;
    if (num_feedback >= 1) {
      if (conversationalScore > 0) {
        // if user has convo score : (convo score considered as 1 date) avg of all date feedbacks
        convoRateAvg = (conversationalScore / (num_feedback + 1))
        + ((sum_score / num_feedback) * 0.05) * (num_feedback / (num_feedback + 1));
      } else {
        // user has no convo score : avg all past feedback score
        convoRateAvg = (sum_score / num_feedback) * 0.05;
      }
    } else {
      // user has no date = 0
      convoRateAvg = null;
    }

    // if user no date == 0 : rating = '-/5'
    userData1 = { convoRateAvg: convoRateAvg?.toFixed(1) || '-' };

    localStorage.setItem(USER_DATA1, JSON.stringify(userData1));

    setPastDates(past_dates);
    setPastCancelledDates(past_cancelled_dates);

    let doneIteration2 = false;
    matchSnapshot2?.forEach((match) => {
      const matchData = match.data();
      if ((!matchData.dateCanceled || matchData.askForReschedule) && !doneIteration2) {
        setNextMatch(matchData);
        // console.log('matchData next', matchData);
        doneIteration2 = true;
      }
    });

    const females = [
      // prod
      'Krk92e5Rb2RMANi1ZmbCs8kerQ82',
      'iwEBBVcQRrP14ZWrQMpGYqo4iSz2',
      'Ezr3bW49fxP5w0NB6RD0Ykr0fhL2',
      'zQisHhbyvjYAmNwb51TnzpHgAgo2',
      'EyeV0InqJ0ZCpQ8E3mYMb5Td3um2',
      'mqkA9B8rAcO5CISeFcL2WTeYuA52',
      '0WOeDKWdEoQCIOfTSSf15gk6LVI2',
      'BcdiSiqxZYg8dbiKAmQiZGDsBZx2',
      // 'Jfy4ETdYpLS19zaCMmFobBaJOuU2',
      // '6oT5wMezPogxPPvboatLjnKYpVZ2',

      // dev
      '3aa4UcfFv2SkRQmc9Pho6c4tCWi1',
      'E1OckB1M0xb6BhjKS1UwxKMGL1J2',
    ];

    const males = [
      // prod
      'a9HbQ9dQWhfOUkg8yRYeuPs1ynD2',
      'UWlVSUR6rrd3oNnhdYyuvbraNMB2',
      'UrfkX8AS1KOREgwE39ORBhVBJd73',
      'siPR50fPd2VDgwE50ihUpMnWRJH3',
      'oGRcZxwntyR2SzjO79G0dR9Ze8I3',
      '5nRdNdBJEKfsHB8Cd9K8D93AyiN2',
      'blp61PgpSGaVsrynODJRYNor3uR2',
      '5ZkE8BYhH7aSPXmokz0Jv2ktDoz2',

      // dev
      'zxWWRpvyjdcydDB4h6IEF0GOgvl2',
      'y89onEOrFePwyfh7P6s9eY864fB3',
    ];

    const userRecommendationRef = doc(firestore, `userRecommendation/${itsYou.uid}`);
    const userRecommendationSnap = await getDoc(userRecommendationRef);

    let recommendUids = [];
    if (userRecommendationSnap.exists()) {
      const userRecommendation = userRecommendationSnap.data();
      if (userRecommendation.recommend?.length) {
        recommendUids = userRecommendation.recommend.map((peopleRecommend) => peopleRecommend.uid);
      }
    }

    const oppositeGender = userData?.signupInfo?.gender === 'Male' ? females : males;

    const peopleRef = collection(firestore, 'user');
    const queryPeople = firestoreQuery(peopleRef, where('signupInfo.uid', 'in', recommendUids.length ? recommendUids : oppositeGender));

    const peopleSnapshot = await getDocs(queryPeople);
    const people = [];
    peopleSnapshot?.forEach((match) => {
      people.push(match.data());
    });

    setExplorePeople(people);
    setLikedPeople(userLikes);

    const envRef = doc(firestore, 'environment/settings');
    const envSnap = await getDoc(envRef);

    if (envSnap.exists()) {
      const envData = envSnap.data();
      setEnv(envData);
    }
  }
}

export function setLikedPeople(rec) {
  localStorage.setItem(LIKED_PEOPLE, JSON.stringify(rec));
}

export function getLikedPeople() {
  const retval = localStorage.getItem(LIKED_PEOPLE);

  return JSON.parse(retval) || '';
}

export function setPastDates(data) {
  localStorage.setItem(PAST_DATES, JSON.stringify(data));
}

export function getPastDates() {
  const retval = localStorage.getItem(PAST_DATES);
  return JSON.parse(retval) || '';
}

export function setPastCancelledDates(data) {
  localStorage.setItem(PAST_CANCELLED_DATES, JSON.stringify(data));
}

export function getPastCancelledDates() {
  const retval = localStorage.getItem(PAST_CANCELLED_DATES);
  return JSON.parse(retval) || '';
}

export function setEnv(data) {
  localStorage.setItem(ENVIRONMENT, JSON.stringify(data));
}

export function getEnv() {
  const retval = localStorage.getItem(ENVIRONMENT);
  return JSON.parse(retval) || '';
}

export function setExplorePeople(people) {
  localStorage.setItem(EXPLORE_PEOPLE, JSON.stringify(people));
}

export function getExplorePeople() {
  const retval = localStorage.getItem(EXPLORE_PEOPLE);

  return JSON.parse(retval) || '';
}

export function setNextMatch(match) {
  localStorage.setItem(NEXT_MATCH, JSON.stringify(match));
}

export function getNextMatch() {
  const retval = localStorage.getItem(NEXT_MATCH);

  return JSON.parse(retval) || '';
}

export function getTotalMatchCount() {
    const retval = localStorage.getItem(TOTAL_MATCH_COUNT);
    return retval || 0
}

export function setTotalMatchCount(count) {
    localStorage.setItem(TOTAL_MATCH_COUNT, count);
}

export function setLastMatch(match) {
  localStorage.setItem(LAST_MATCH, JSON.stringify(match));
}

export function setTodaysMatch(match) {
  localStorage.setItem(TODAYS_MATCH, JSON.stringify(match));
}

export function getLastMatch() {
  const retval = localStorage.getItem(LAST_MATCH);

  return JSON.parse(retval) || '';
}

export function getTodaysMatch() {
  const retval = localStorage.getItem(TODAYS_MATCH);

  return JSON.parse(retval) || '';
}

export function getUserData() {
  const retval = localStorage.getItem(USER_DATA);

  return JSON.parse(retval) || '';
}
export function getUserData1() {
  const retval = localStorage.getItem(USER_DATA1);

  return JSON.parse(retval) || '';
}
export async function getUserDataForAmplitude(data) {
  const docRef = doc(firestore, `userPrivateData/${data}`);
  // const docRef1 = doc(firestore, `user/${data.uid}`);
  const docSnap = await getDoc(docRef);
  // const docSnap1 = await getDoc(docRef1);
  const userData = docSnap.data();
  return userData;
}
export function setProfilePicture(value) {
  localStorage.setItem(PROFILE_PICT, value);
}

export function getProfilePicture() {
  const link = localStorage.getItem(PROFILE_PICT);
  return link || '';
}

export function setGettingToKnowYou(value) {
  const data = JSON.stringify(value || {});
  localStorage.setItem(GETTING_TO_KNOW_YOU, data);
}

export function getGettingToKnowYou() {
  const retval = localStorage.getItem(GETTING_TO_KNOW_YOU);

  return JSON.parse(retval) || '';
}

export function setReferralInfo(value) {
  const data = JSON.stringify(value || {});
  localStorage.setItem(REFERRAL_INFO, data);
}

export function getReferralInfo() {
  const retval = localStorage.getItem(REFERRAL_INFO);

  return JSON.parse(retval) || '';
}

export function setLoginPlatformType(rec) {
  localStorage.setItem(LOGIN_PLATFORM_TYPE, JSON.stringify(rec));
}

export function getLoginPlatformType() {
  const retval = localStorage.getItem(LOGIN_PLATFORM_TYPE);

  return JSON.parse(retval) || '';
}

export function setUserId(rec) {
  localStorage.setItem(U_ID, JSON.stringify(rec));
}

export function getUserId() {
  const retval = localStorage.getItem(U_ID);

  return JSON.parse(retval) || '';
}
export async function getPricingSubscription() {
  const docRef1 = await doc(firestore, 'environment/subscription/Pricing', 'price');
  const envSnapData1 = await getDoc(docRef1);
  const docRef2 = await doc(firestore, 'environment/subscription/Pricing', 'ApiID');
  const envSnapData2 = await getDoc(docRef2);
  // console.log('getPricingSubscription', [envSnapData1?.data(), envSnapData2?.data()]);
  return [envSnapData1?.data(), envSnapData2?.data()];
}
