/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Badge, BottomNavigation, BottomNavigationAction, Paper,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { signOut } from '@firebase/auth';
import images from '../../Configs/images';
// import { Logout } from '@mui/icons-material';
import ConfirmationModal from '../ConfirmationModal';
import { auth } from '../../Configs/firebase';
import { amplytics } from '../../utils/helper';
import { getUserData } from '../../utils/storage';

export default function BottomNav({ classes, active }) {
  const history = useHistory();
  const [logOutConfirm, setLC] = useState(false);

  const handleLogOut = () => {
    signOut(auth)
      .then(() => {
        // window.location.replace('/login');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTo = (route) => () => {
    amplytics(`Clicks on ${route.replace('/', '')} tab`);
    history.push(route);
  };

  const userData = getUserData();

  useEffect(() => {
    // console.log(userData);
    if (!userData?.signupInfo?.uid) {
      history.push('setup-profile');
    }
  }, [userData]);

  return (
    <>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          height: 56,
          zIndex: 3,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={active}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
          className={classes.nav}
        >
          <BottomNavigationAction id="btn-menu-home" onClick={() => history.push('/')} label="Home" icon={<img alt="home" src={active === 0 ? images.HomeIconActive : images.HomeIcon} width="18" height="18" />} />
          <BottomNavigationAction id="btn-menu-chat" onClick={() => history.push('/chat')} label="Chat" icon={<Badge badgeContent={0} color="error"><img alt="message" src={active === 1 ? images.ChatIconActive : images.ChatIcon} width="18" height="18" /></Badge>} />
          <BottomNavigationAction id="btn-menu-kit" onClick={handleTo('/explore')} label="Explore" icon={<img alt="kit" src={active === 2 ? images.FlashlightIconActive : images.FlashlightIcon} width="18" height="18" />} />
          {/* <BottomNavigationAction onClick={()=>setLC(true)}
          label="Logout" icon={<Logout sx={{color:'#b2b2b2', fontSize:18}} />} /> */}
          <BottomNavigationAction id="btn-menu-dates" onClick={() => history.push('/dates')} label="Dates" icon={<img alt="dates" src={active === 3 ? images.DatesBrown : images.DatesGrey} width="18" height="18" />} />
          <BottomNavigationAction id="btn-menu-settings" onClick={() => history.push('/settings')} label="Settings" icon={<img alt="settings" src={active === 4 ? images.SettingsIconActive : images.SettingsIcon} width="18" height="18" />} />
        </BottomNavigation>
      </Paper>
      <ConfirmationModal title="Logout" open={logOutConfirm} handleClose={() => setLC(false)} handleAgree={handleLogOut} />
    </>
  );
}
