/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Box, Container, Divider, Grid, SwipeableDrawer,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { EmailIcon, TelegramIcon, WhatsappIcon } from 'react-share';
import { OrangeButton } from '../../Elements';
import images from '../../Configs/images';
import { amplytics } from '../../utils/helper';
import { getReferralInfo } from '../../utils/storage';

export default function Component({ classes, history }) {
  useEffect(() => {
    document.title = 'Kopi Date Experience - Date Concluded';
    amplytics('Concluded View');
  }, []);

  const referralInfo = getReferralInfo();

  const message = `Have%20you%20tried%20Kopi%20Date?%20Sign%20up%20with%20my%20promo%20code:%20${referralInfo?.promocode}%20and%20you%20will%20get%20off%20the%20waitlist,%20PLUS%20a%20VIP%20pass!%20%F0%9F%A4%9F%F0%9F%8F%BB%20Come%20check%20it%20out!%20https://kopidate.com.au`;

  const [openToShare, setOpenToShare] = useState(false);
  const toggleOpenToShare = () => {
    if (!openToShare) {
      amplytics('Clicks on referral');
    }
    setOpenToShare(!openToShare);
  };

  const handleRedirect = (link) => () => {
    // const linkArr = link.split('/');
    // const destiny = linkArr[linkArr.length - 1];
    // amplytics(`Home CTA redirect to ${destiny}`);
    window.open(link, '_blank');
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12} style={{ position: 'relative' }}>
          <Container sx={{ pt: 2, px: 4 }}>
            <div style={{ textAlign: 'right' }} onClick={() => history.replace('/availability')}>
              <CloseRounded fontSize="small" />
            </div>

            <div className="center title">
              Date Concluded
            </div>

            <div className="center desc">
              We are now better equipped for your next experience! 🙌🏻
            </div>

            <Divider sx={{ my: 2 }} />

            <div className="center benefit">Have a free Kopi Date!</div>

            <div className="desc">
              Kopi Date is an early-stage startup
              that aims to move away from the superficiality of online dating.
            </div>

            <div className="desc">
              Skip the waitlist for your friend(s)
              and receive a FREE Kopi Date Credit! ☕
            </div>

            <div className="center code-container">
              <div style={{ color: '#606060', fontSize: 14, fontWeight: '500' }}>YOUR REFERRAL CODE</div>
              <div className="code">{referralInfo?.promocode}</div>
              <OrangeButton onClick={toggleOpenToShare} text="SHARE MAGIC LINK" rounded styles={{ padding: '6px 32px', fontWeight: '600', fontSize: 16 }} />
            </div>

          </Container>

          <Box className="center" sx={{ my: 3 }}>
            <img src={images.Socialkd} alt="social-kd" style={{ width: '80%' }} />
          </Box>
          <Box sx={{ p: 2, background: '#fff' }}>
            <Box className="flex align-items-center adhoc" onClick={() => history.push('/adhoc')}>
              <img src={images.Adhocimage} alt="social-kd" style={{ width: '40%' }} />
              <div>
                <div className="adhoc-title">Additional Kopi Date</div>
                <div className="desc">Go for an additional Kopi Date this month (ad-hoc)!</div>
                <div className="flex align-items-center adhoc-star">
                  <img src={images.Star} alt="star" height="16" width="16" />
                  <div>Popular choice!</div>
                </div>
              </div>
            </Box>
          </Box>

        </Grid>
      </Grid>
      <SwipeableDrawer
        anchor="bottom"
        open={openToShare}
        onClose={toggleOpenToShare}
        onOpen={() => null}
      >
        <Box
          sx={{ width: 'auto', textAlign: 'center' }}
          role="presentation"
        >
          <div style={{
            marginTop: 10, fontSize: 16, fontWeight: 'bold', color: '#000',
          }}
          >
            Share using
          </div>
          <div style={{
            padding: '18px 20px 40px', display: 'flex', gap: 4, justifyContent: 'center',
          }}
          >
            <WhatsappIcon size={45} round onClick={handleRedirect(`https://api.whatsapp.com/send?text=${message}`)} />
            <TelegramIcon size={45} round onClick={handleRedirect(`https://t.me/share/url?url={https://kopidate.com.au}&text=${message}`)} />
            <a href={`sms:&body=${message}`}>
              <EmailIcon size={45} round />
            </a>
          </div>
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
