import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, FacebookAuthProvider, getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// const firebaseConfig = {
//   apiKey: 'AIzaSyCn2OyUyvdftSYYz8fU2h_wrtFXpDbUOqk',
//   authDomain: 'kopi-date-v1.firebaseapp.com',
//   databaseURL: 'https://kopi-date-v1.firebaseio.com',
//   projectId: 'kopi-date-v1',
//   storageBucket: 'kopi-date-v1.appspot.com',
//   messagingSenderId: '587763676786',
//   appId: '1:587763676786:web:0075e28b453fdd2464b4eb',
//   measurementId: 'G-2GQ7F2P2B1',
// };

const initFirebase = initializeApp(firebaseConfig);

export const GoogleProvide = new GoogleAuthProvider();
export const FacebookProvide = new FacebookAuthProvider();
export const google_analytic = getAnalytics(initFirebase);
export const auth = getAuth(initFirebase);
export const db = getDatabase(initFirebase);
export const firestore = getFirestore(initFirebase);
export const storage = getStorage(initFirebase);
export const fFunction = getFunctions(initFirebase, 'asia-east2');
