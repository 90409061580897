/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from 'react';
import {
  BrowserRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { LinearProgress, Snackbar } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import {
  ref, onValue, push, set,
} from '@firebase/database';
import amplitude from 'amplitude-js';
import { ExportJsonCsv } from 'react-export-json-csv';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db, firestore } from './Components/Configs/firebase';
import pages from './Components/Pages';
import './App.css';
// import { brown } from './Components/Configs/colors';
import images from './Components/Configs/images';
import { setUserData } from './Components/utils/storage';
// import { analytics } from './Components/utils/helper';
import Analytics from './Components/Configs/Analytics';
import { KitWidget } from './Components/Elements';
import sendSMS from './Components/sendSMS';
import Quiz from './Components/Pages/Quiz';

// import ReactGA from 'react-ga';
// import {setUserId} from 'firebase/analytics'
// import users from './Components/Assets/save_file.json';
// import { collection, getDocs } from 'firebase/firestore';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [neverSIgnUpUsers, setNSUU] = useState([]);

  const [noAuth, setNoAuth] = useState(true);

  const [showInstall, setShowInstall] = useState(false);

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  useEffect(() => {
    const configRef = ref(db, 'config/noAuthRequired');
    // set(testRef, {
    //   users:{
    //     dfsejfskfsdf:{
    //       displayName:'dsff',
    //       email: 'efsefsef@mail.id'
    //     },
    //     fghjjhhftfguy:{
    //       displayName:'dsff',
    //       email: 'efsefsef@mail.id'
    //     }
    //   },
    //   history:[]
    // })

    onValue(configRef, (snapshot) => {
      console.log('snapshot', snapshot.val());
      setNoAuth(snapshot.val());
      auth.onAuthStateChanged(async (user) => {
        setLoading(true);
        if (user) {
          await setUserData(user);
          // setUserId(user?.uid)
          // ReactGA.initialize('UA-154578745-6', {
          //   debug: true,
          //   titleCase: false,
          //   gaOptions: {
          //     userId: user.uid
          //   }
          // });

          setLoggedIn(true);
        } else setLoggedIn(false);

        setLoading(false);
      });
    });

    if (isIos() && !isInStandaloneMode()) {
      console.log('ios');
      setShowInstall(true);
    } else {
      setShowInstall(false);
    }
  }, []);

  const headers = [
    {
      key: 'uid',
      name: 'uid',
    },
    {
      key: 'user1_feedback_score',
      name: 'user1_feedback_score',
    },
  ];

  // const getUsersThatNeverSignup = async () => {
  //   const docRef1 = doc(firestore, 'test/test105');
  //   const docSnap1 = await getDoc(docRef1);
  //   // console.log('oi', docSnap1.data());
  //   if (docSnap1.exists()) {
  //     const allData = Object.keys(docSnap1.data()).map((key) => {
  //       const data = { ...docSnap1.data()[key][0] };
  //       data.uid = key;
  //       return data;
  //     });
  //     setNSUU(allData);
  //   }
  // };

  // useEffect(() => {
  //   getUsersThatNeverSignup();
  // }, []);

  const rootRef = useRef();

  return (
    <div style={{ overflow: 'hidden', position: 'relative', zIndex: 0 }} ref={rootRef}>
      {/* <ExportJsonCsv headers={headers} items={neverSIgnUpUsers}>Export</ExportJsonCsv> */}
      {loading
        ? (
          <>
            <LinearProgress />
            <div style={{
              textAlign: 'center',
              background: '#fef8f4',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
            >
              <img alt="loading" src={images.Loading} style={{ width: 100, height: 100 }} />
            </div>
          </>
        )
        : (
          <Router>
            <Analytics />
            <KitWidget rootRef={rootRef} />
            {loggedIn || noAuth
              ? (
                <Switch>
                  <Route exact path="/" component={pages.Home} />
                  <Route exact path="/home" component={pages.Home} />
                  <Route exact path="/knowing-the-real-you" component={pages.KnowingTheRealYou} />

                  <Route exact path="/share-feedback" component={pages.ShareFeedback} />
                  <Route exact path="/explore-people" component={pages.ExplorePeople} />
                  <Route exact path="/availability" component={pages.UpdateAvailability} />
                  <Route exact path="/date-details" component={pages.DateDetails} />
                  <Route exact path="/upcoming-date" component={pages.UpcomingDate} />
                  <Route exact path="/reschedule-date" component={pages.RescheduleDate} />

                  <Route exact path="/conclude" component={pages.Conclude} />
                  <Route exact path="/conclude/:matchId" component={pages.Conclude} />

                  <Route exact path="/chat" component={pages.Chat} />
                  <Route exact path="/chat/:matchId/:userHash" component={pages.Chat} />
                  <Route exact path="/chat/:matchId/:userHash/reschedule" component={pages.AdminChat} />

                  <Route exact path="/kit" component={pages.Kit} />
                  <Route exact path="/kit/:matchId/:userHash" component={pages.Kit} />

                  <Route exact path="/dates" component={pages.Dates} />

                  <Route exact path="/settings" component={pages.Settings} />
                  {/* <Route exact path="/faq" component={pages.Faq} /> */}
                  <Route exact path="/start-screen" component={pages.StartScreen} />
                  <Route exact path="/title-screen" component={pages.TitleScreen} />
                  <Route exact path="/game-screen" component={pages.GameScreen} />
                  <Route exact path="/extendpause" component={pages.ExtendPause} />
                  <Route exact path="/adhoc" component={pages.AdHoc} />
                  <Route exact path="/subscription" component={pages.Subscription} />
                  <Route exact path="/game" component={pages.Games} />
                  <Route exact path="/game/:gameId" component={pages.Games} />
                  <Route exact path="/feedback" component={pages.Feedback} />
                  <Route exact path="/feedback/:typeformId" component={pages.Feedback} />
                  <Route exact path="/date-message" component={pages.DateMessage} />
                  <Route exact path="/date-message/:matchId" component={pages.DateMessage} />
                  <Route exact path="/referral" component={pages.Referral} />
                  <Route exact path="/date-concluded" component={pages.DateConcluded} />
                  <Route exact path="/explore" component={pages.Explore} />
                  <Route exact path="/explore/topic" component={pages.ExploreTopic} />
                  <Route exact path="/reschedule-fee" component={pages.RescheduleFee} />
                  <Route exact path="/reschedule-fee/:matchId" component={pages.RescheduleFee} />
                  <Route exact path="/test" component={pages.Test} />

                  <Route exact path="/setup-profile" component={pages.SetupProfile} />
                  <Route exact path="/setup-profile/:field" component={pages.SetupProfile} />
                  <Route exact path="/waitlisted" component={pages.Waitlisted} />
                  <Route exact path="/payment" component={pages.Payment} />
                  <Route exact path="/calendly" component={pages.Calendly} />
                  <Route exact path="/quiz" component={Quiz} /> {/* For Deeplink usage buffer page */}
                  <Route component={pages.Home} />
                  {/* <Redirect to="/" /> */}
                </Switch>
              )
              : (
                <Switch>
                  <Route path="/login" component={pages.Login} />
                    <Route exact path="/quiz" component={Quiz} /> {/* For Deeplink usage buffer page */}
                    <Route exact path="/conclude" component={Quiz} /> {/* For Deeplink usage buffer page */}
                    <Route exact path="/availability" component={Quiz} /> {/* For Deeplink usage buffer page */}
                    <Route exact path="/chat" component={Quiz} /> {/* For Deeplink usage buffer page */}
                    <Route exact path="/settings" component={Quiz} /> {/* For Deeplink usage buffer page */}
                    <Route exact path="/knowing-the-real-you" component={Quiz} /> {/* For Deeplink usage buffer page */}
                  {/* <Route path="/onboarding" component={pages.Onboarding} /> */}
                  <Route component={pages.Login} />
                  {/* <Redirect to="/login" from='*' /> */}
                </Switch>
              )}
          </Router>
        )}
      {/* <Snackbar
        open={showInstall}
        onClose={() => setShowInstall(false)}
        message="I love snacks"
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div className="pop">
            Install this web app on your iPhone:
            tap
            {' '}
            <img
              alt="share"
              src={images.Share}
              style={{
                height: 20,
                width: 20,
                marginBottom: -5,
              }}
            />
            {' '}
            and then add to homescreen
          </div>
          <ArrowDropDown sx={{ color: '#ccc', marginBottom: -2 }} fontSize="large" />
        </div>
      </Snackbar> */}
    </div>
  );
}

export default App;
